export default {
  data() {
    return {
      contextMenuVisible: false,
      rightClickPositionX: 0,
      rightClickPositiony: 0
    };
  },
  methods: {
    showContextMenu(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuVisible = true;
      });
    }
  }
};
