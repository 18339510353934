class AdditionalCostsCreateModel {
  totalPrice: number;
  amount: number;
  additionalCostsRegistryId: number;
  constructor(
    totalPrice: number,
    amount: number,
    additionalCostsRegistryId: number
  ) {
    this.totalPrice = totalPrice;
    this.amount = amount;
    this.additionalCostsRegistryId = additionalCostsRegistryId;
  }
}

class AdditionalCostsUpdateModel {
  totalPrice: number;
  amount: number;
  additionalCostsRegistryId: number;
  id: number;
  constructor(
    totalPrice: number,
    amount: number,
    additionalCostsRegistryId: number,
    id: number
  ) {
    this.totalPrice = totalPrice;
    this.amount = amount;
    this.additionalCostsRegistryId = additionalCostsRegistryId;
    this.id = id < 0 ? 0 : id;
  }
}

export { AdditionalCostsCreateModel, AdditionalCostsUpdateModel };
