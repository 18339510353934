<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="computedProps.value"
    transition="scale-transition"
    :disabled="!povolenaEditace"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip v-model="showTooltip" top nudge-bottom="15">
        <template v-slot:activator="{}">
          <v-text-field
            ref="timePickerTextField"
            :value="computedTimeFormatted"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="{ ...attrs, ...computedProps }"
            v-on="{ ...on, ...$listeners }"
            :style="textFieldStyle"
            :class="computedClass"
            :hide-details="componentIsFilter"
          ></v-text-field>
        </template>
        <span>{{ errorMessage }}</span>
      </v-tooltip>
    </template>
    <v-time-picker
      v-if="menu"
      @change="onTimeChange"
      v-model="time"
      full-width
      format="24hr"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Zavřít
      </v-btn>
      <v-btn text color="primary" @click="setValue">
        Zvolit
      </v-btn></v-time-picker
    >
  </v-menu>
</template>

<script>
import { VTextField } from 'vuetify/lib';
import isInViewport from '../../mixins/isInViewportMixin';

export default {
  extends: VTextField,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    textFieldStyle: {
      type: Object,
      default: null
    },
    textFieldClass: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      menu: false,
      time: null,
      changedTime: null,
      componentIsFilter: false
    };
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace;
      props.rules = [];
      if (this.jePovinne) {
        props.rules.push(value => !!value || 'Pole je povinné');
      }
      return props;
    },
    computedClass() {
      return this.textFieldClass ?? {};
    },
    computedTimeFormatted() {
      return this.formatTime(this.computedProps.value);
    }
  },
  methods: {
    onTimeChange(newValue) {
      this.changedTime = newValue;
    },
    setValue() {
      const oldTime = this.$props.value;

      if (this.changedTime) {
        const [hours, minutes] = this.changedTime.split(':');
        const newTime = this.$moment(oldTime)
          .hours(hours)
          .minutes(minutes)
          .toISOString(true);
        this.$emit('input', newTime);
        this.$refs.menu.save(newTime);
      } else {
        this.$refs.menu.save(oldTime);
      }
    },
    formatTime(inputTime) {
      if (!inputTime) return null;

      if (inputTime.length === 10) {
        this.time = this.$moment(inputTime).format('HH:mm');
      }

      return this.time;
    },
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    this.time = this.$moment(this.computedProps.value).format('HH:mm');

    this.$watch(
      () => this.computedProps.value,
      function(newVal) {
        this.time = this.$moment(newVal).format('HH:mm');
      }
    );

    // this.$watch(
    //   () => this.$refs?.timePickerTextField?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.timePickerTextField.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  }
};
</script>
