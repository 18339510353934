<template>
  <div>
    <v-tooltip v-model="showTooltip" top nudge-bottom="15">
      <template v-slot:activator="{}">
        <v-textarea
          ref="textarea"
          v-bind="computedProps"
          v-on="$listeners"
          :hide-details="componentIsFilter"
          no-resize
        ></v-textarea>
      </template>
      <span>{{ errorMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VTextarea } from 'vuetify/lib';
import isInViewport from '../../mixins/isInViewportMixin';

export default {
  extends: VTextarea,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    maxLength: { type: Number }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      componentIsFilter: false
    };
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace && props.clearable;
      props.prependIcon =
        !this.componentIsFilter && !props.prependIcon
          ? 'mdi-alphabetical'
          : props.prependIcon;
      props.rules = [];
      if (this.jePovinne) {
        props.rules.push(value => !!value || 'Pole je povinné');
      }
      return props;
    }
  },
  methods: {
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    // this.$watch(
    //   () => this.$refs?.textarea?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.textarea.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  }
};
</script>

<style lang="scss">
.v-textarea .v-text-field__slot textarea {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
</style>
