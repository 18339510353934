import _ from 'lodash';

let toDelete = []; // not exists in new array
let toAdd = []; // not exists in original array
let toUpdate = []; // exists in both arrays

const getDifferences = (originalArray, newArray, identificator = 'id') => {
  if (!originalArray || !newArray) {
    return { toDelete, toAdd, toUpdate };
  }

  if (
    originalArray &&
    originalArray.length > 0 &&
    typeof originalArray[0] == 'object'
  ) {
    originalArray = originalArray.map(el => el[identificator]);
  }

  if (newArray && newArray.length > 0 && typeof newArray[0] == 'object') {
    newArray = newArray.map(el => el[identificator]);
  }

  toDelete = _.differenceBy(originalArray, newArray);
  toAdd = _.differenceBy(newArray, originalArray);
  toUpdate = _.intersectionBy(originalArray, newArray);

  return { toDelete, toAdd, toUpdate };
};

export { getDifferences };
