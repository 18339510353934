<template>
  <div>
    <v-tooltip v-model="showTooltip" top nudge-bottom="10">
      <template v-slot:activator="{}">
        <v-text-field
          ref="inputNumber"
          v-bind="{ ...computedProps }"
          :style="stylesObject"
          v-onlyDigits
          type="number"
          @input="onInput"
          @keypress="onKeyPress"
          :hide-details="componentIsFilter"
        ></v-text-field>
      </template>
      <span>{{ errorMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VTextField } from 'vuetify/lib';
import isInViewport from '../../mixins/isInViewportMixin';

export default {
  extends: VTextField,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 2147483647 },
    maska: {
      type: String,
      default: null
    },

    stylesObject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      componentIsFilter: false
    };
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace && props.clearable;
      props.prependIcon =
        !this.componentIsFilter && !props.prependIcon
          ? 'mdi-numeric'
          : props.prependIcon;
      props.rules = [];
      if (this.jePovinne) {
        props.rules.push(
          value =>
            ((!!value || value === '0' || value === 0) && !isNaN(value)) ||
            'Pole je povinné'
        );
      }
      if (!isNaN(this.max)) {
        props.rules.push(
          value =>
            (value ?? 0) <= this.max || `Hodnota musí být nižší než ${this.max}`
        );
      }
      if (!isNaN(this.min)) {
        props.rules.push(
          value =>
            (value ?? 0) >= this.min || `Hodnota musí být vyšší než ${this.min}`
        );
      }

      return props;
    }
  },
  directives: {
    onlyDigits: {
      bind(el) {
        el.addEventListener('keypress', evt => {
          evt = evt ?? window.event;
          const charCode = evt.which ? evt.which : evt.keyCode;
          // omezeni na cela cisla
          if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            // charCode !== 46 && // = "."
            charCode !== 44 // = ","
            && charCode !== 45 // = "-"
          ) {
            evt.preventDefault();
          }
        });
      }
    }
  },
  methods: {
    onInput(e) {
      if (!e || e == '') {
        this.$emit('input', null);
      } else {
        this.$emit('input', parseFloat(e));
      }
    },
    onKeyPress(event) {
      // restrict to 3 decimal places
      if (
        this.$refs?.inputNumber?.$refs?.input?.value != null &&
        this.$refs.inputNumber.$refs.input.value.indexOf('.') > -1 &&
        this.$refs.inputNumber.$refs.input.value.split('.')[1].length > 2
      ) {
        event.preventDefault();
      }
    },
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    // this.$watch(
    //   () => this.$refs?.inputNumber?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.inputNumber.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  }
};
</script>

<style lang="scss">
input[type='number']::-webkit-inner-spin-button {
  transform: translateY(-4px);
}
</style>
