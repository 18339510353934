<template>
  <div>
    <v-tooltip v-model="showTooltip" top nudge-bottom="10">
      <template v-slot:activator="{}">
        <v-text-field
          ref="inputText"
          v-bind="{ ...computedProps }"
          v-on="{ ...computedListeners }"
          :style="stylesObject"
          :value="computedValue"
          @input="e => (computedValue = e)"
          :hide-details="componentIsFilter"
        ></v-text-field>
      </template>
      <span>{{ errorMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VTextField } from 'vuetify/lib';
import isInViewport from '../../mixins/isInViewportMixin';

export default {
  extends: VTextField,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    maxLength: {
      type: Number
    },
    stylesObject: {
      type: Object,
      default: null
    },
    isCharCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      componentIsFilter: false
    };
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace && props.clearable;
      props.prependIcon =
        !this.componentIsFilter && !props.prependIcon
          ? 'mdi-alphabetical'
          : props.prependIcon;
      props.rules = [];
      if (this.jePovinne) {
        props.rules.push(value => !!value || 'Pole je povinné');
      }
      return props;
    },
    computedListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.computedValue = event;
        }
      });
    },
    computedValue: {
      get() {
        if (this.isCharCode && this.value) {
          return String.fromCharCode(this.value);
        } else return this.value;
      },
      set(val) {
        if (this.isCharCode) {
          this.$emit('input', val !== '' ? val.charCodeAt(0) : null);
        } else {
          this.$emit('input', val);
        }
      }
    }
  },
  methods: {
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    // this.$watch(
    //   () => this.$refs?.inputText?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.inputText.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  }
};
</script>
