/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const state = {
  growingActivitiesRegistry: [],
  growingActivityRegistry: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getGrowingActivitiesRegistry({ commit }, filterOptions) {
    try {
      const { data } = await axios.get('growingActivityRegistry/get', {
        params: { date: filterOptions?.date ?? null }
      });
      const filteredData = filterOrderGetPage(data, filterOptions);
      commit('growingActivitiesRegistry', filteredData);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getGrowingActivityRegistryById({ commit }, id) {
    try {
      const { data } = await axios.get(`growingActivityRegistry/get/${id}`);
      commit('growingActivityRegistry', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createGrowingActivityRegistry({}, createGrowingActivityRegistryModel) {
    try {
      await axios.post(
        `growingActivityRegistry/create`,
        createGrowingActivityRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateGrowingActivityRegistry({}, updateGrowingActivityRegistryModel) {
    try {
      await axios.put(
        `growingActivityRegistry/update`,
        updateGrowingActivityRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteGrowingActivityRegistry({}, id) {
    try {
      await axios.delete(`growingActivityRegistry/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
