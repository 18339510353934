/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { LoginResponse } from './models/authModels';

const state = {
  isAuthenticated: false,
  token: '',
  error: null,
  userId: null,
  userRoles: []
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async loginUser({ commit }, loginModel) {
    try {
      const { data } = await axios.post<LoginResponse>(
        'auth/login',
        loginModel
      );
      commit('token', data.token);
      commit('isAuthenticated', true);
      commit('error', null);
      commit('userId', data.userId);
      commit('userRoles', data.roles);
    } catch (e) {
      if (!e) {
        commit('error', 'Připojení na službu není dostupné');
      } else if (e?.status != 500) {
        commit('error', Array.isArray(e.data) ? e.data[0] : e.data);
      } else {
        commit('error', e);
      }
    }
  },
  logoutUser({ commit }) {
    try {
      commit('isAuthenticated', false);
      commit('token', '');
    } catch (e) {
      commit('error', Array.isArray(e.data) ? e.data[0] : e.data);
    }
  },
  async registerUser({}, registerModel) {
    try {
      await axios.post('auth/register', registerModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async forgotPassword({}, forgotPasswordModel) {
    try {
      await axios.post('auth/forgotPassword', forgotPasswordModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async resetPassword({}, resetPasswordModel) {
    try {
      await axios.post('auth/resetPassword', resetPasswordModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async requestPasswordReset({}, requestPasswordResetModel) {
    try {
      await axios.post('auth/requestPasswordReset', null, {
        params: requestPasswordResetModel
      });
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
