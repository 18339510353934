/* eslint-disable no-prototype-builtins */
<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <v-tabs v-model="tab" centered icons-and-text show-arrows>
              <v-tab href="#tab-details">
                <span v-html="'&nbsp'"></span> Detail
                <v-icon>mdi-book-open-outline</v-icon>
              </v-tab>
              <v-tab href="#tab-sortiment">
                <b
                  >{{
                    deliveryNote.assortment && deliveryNote.assortment.length
                  }} </b
                >Sortiment
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-tab>
              <!-- <v-tab href="#tab-additionalCosts">
                <b>{{
                  deliveryNote.additionalCosts &&
                    deliveryNote.additionalCosts.length
                }}</b
                >Vícenáklady
                <v-icon>mdi-note-plus-outline</v-icon>
              </v-tab> -->
              <v-tab href="#tab-traffic">
                <span v-html="'&nbsp'"></span> Doprava
                <v-icon>mdi-truck-delivery-outline</v-icon>
              </v-tab>
              <v-tab href="#tab-otherCosts">
                <b
                  >{{
                    deliveryNote.otherCosts && deliveryNote.otherCosts.length
                  }} </b
                >Ostatní náklady
                <v-icon>mdi-pencil-plus-outline</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="height:30rem;">
              <v-tab-item
                value="tab-details"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" class="py-0"
                        ><InputText
                          label="Číslo"
                          v-model="deliveryNote.sequenceNumber"
                          :povolenaEditace="formMode != formModes.read"
                          :jePovinne="formMode == formModes.edit"
                          :hint="
                            formMode == formModes.new
                              ? 'V případě nevyplnění čísla se automaticky vygeneruje nové při uložení'
                              : undefined
                          "
                          :persistent-hint="formMode == formModes.new"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><InputText
                          label="Číslo záměru"
                          v-model="deliveryNote.intentionNumber"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><DatePicker
                          label="Datum"
                          v-model="deliveryNote.date"
                          jePovinne
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><InputNumber
                          label="Celkem sortimentu m³"
                          :value="totalAssortmentM()"
                          :povolenaEditace="false"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Dodavatel"
                          v-model="companyFrom"
                          :items="companies"
                          :item-text="
                            c =>
                              `${c.name} ${(c.city &&
                                c.city.length !== 0 &&
                                '(' + c.city.trim() + ')') ||
                                ''}`
                          "
                          item-value="id"
                          jePovinne
                          hledani
                          :zvolitPrvni="false"
                          :povolenaEditace="formMode != formModes.read"
                          @change="onCompanyFromChange(true)"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Odkud"
                          v-model="deliveryNote.localities"
                          :items="filteredLocalities"
                          item-text="name"
                          item-value="localityId"
                          jePovinne
                          hledani
                          multiple
                          :zvolitPrvni="false"
                          :povolenaEditace="
                            formMode != formModes.read && !!companyFrom
                          "
                          return-object
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Odběratel"
                          v-model="deliveryNote.destinationId"
                          :items="companies"
                          :item-text="
                            c =>
                              `${c.name} ${(c.city &&
                                c.city.length !== 0 &&
                                '(' + c.city.trim() + ')') ||
                                ''}`
                          "
                          item-value="id"
                          jePovinne
                          hledani
                          :zvolitPrvni="false"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <!-- <v-col cols="12" class="py-0"
                        ><InputNumber
                          label="Cena"
                          v-model="deliveryNote.totalPrice"
                          :povolenaEditace="false"
                          prepend-icon="mdi-cash"
                      /></v-col> -->

                      <v-col cols="12" class="py-0"
                        ><Textarea
                          label="Poznámka"
                          rows="6"
                          v-model="deliveryNote.note"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col> </v-row
                  ></v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="tab-sortiment"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container style="height:100%;">
                    <v-row dense style="height:100%;">
                      <v-col cols="12">
                        <v-toolbar dense class="v-toolbar-scrollable">
                          <Button
                            tooltip="Založení nového záznamu"
                            @click="showDialogAssortment(formModes.new)"
                            ><v-icon>mdi-plus</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazení detailu záznamu"
                            @click="showDialogAssortment(formModes.read)"
                            :povolenaEditace="!!selectedRowAssortment"
                            ><v-icon>mdi-file-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Změna údajů záznamu"
                            @click="showDialogAssortment(formModes.edit)"
                            :povolenaEditace="!!selectedRowAssortment"
                            ><v-icon>mdi-pencil</v-icon></Button
                          >
                          <Button
                            tooltip="Zrušení (zneaktivnění) záznamu"
                            @click="deleteRowAssortment"
                            :povolenaEditace="
                              !!selectedRowAssortment &&
                                formMode != formModes.read
                            "
                            ><v-icon>mdi-delete-outline</v-icon></Button
                          >
                          <v-divider vertical class="mx-2"></v-divider>
                          <Button
                            tooltip="Zobrazí nápovědu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-help-circle-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazí tiskovou sestavu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-printer</v-icon></Button
                          >
                        </v-toolbar></v-col
                      >
                      <v-col cols="12" style="height: calc(100% - 56px)">
                        <DataTable
                          :headers="headersAssortment"
                          :items="deliveryNote.assortment"
                          item-key="id"
                          :selectedItem.sync="selectedRowAssortment"
                          @dblclick:row="showDialogAssortment(formModes.read)"
                          :options.sync="optionsAssortment"
                          :server-items-length="totalRecordsAssortment"
                          single-select
                          :hide-default-footer="true"
                          @contextmenu:row="showContextMenuAssortment"
                          height="100%"
                        >
                          <template v-slot:[`item.name`]="{ item }">
                            {{ item.assortmentRegistry.name }}
                          </template>
                          <template v-slot:[`item.identificator`]="{ item }">
                            {{ item.assortmentRegistry.identificator }}
                          </template>
                          <template v-slot:[`item.unitBuyingPrice`]="{ item }">
                            <i v-if="item.customBuyingPrice != null">{{
                              item.customBuyingPrice
                            }}</i>
                            <span v-else>{{
                              item.assortmentRegistry.unitBuyingPrice
                            }}</span>
                          </template>
                          <template v-slot:[`item.unitSellingPrice`]="{ item }">
                            <i v-if="item.customSellingPrice != null">{{
                              item.customSellingPrice
                            }}</i>
                            <span v-else>{{
                              item.assortmentRegistry.unitSellingPrice
                            }}</span>
                          </template>
                          <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount.toString().replace('.', ',') }}
                          </template>
                        </DataTable>
                        <v-menu
                          v-model="contextMenuAssortmentVisible"
                          :disabled="!selectedRowAssortment"
                          :position-x="rightClickPositionX"
                          :position-y="rightClickPositiony"
                          absolute
                          offset-y
                        >
                          <v-list dense>
                            <v-list-item
                              @click="showDialogAssortment(formModes.read)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-file-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Detail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="showDialogAssortment(formModes.edit)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editace</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="deleteRowAssortment">
                              <v-list-item-icon>
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Zrušit</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu></v-col
                      ></v-row
                    >
                  </v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="tab-traffic"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container>
                    <v-row dense>
                      <v-col cols="6" class="py-0"
                        ><Checkbox
                          label="Zobrazit pouze aktuální ceníky dopravného"
                          v-model="showOnlyActualPriceLists"
                          :povolenaEditace="formMode != formModes.read"
                          @change="onShowOnlyActualPriceLists($event)"
                      /></v-col>
                      <v-col cols="6" class="py-0"
                        ><Checkbox
                          label="Zobrazit pouze aktuální auta"
                          v-model="showOnlyActualCars"
                          :povolenaEditace="formMode != formModes.read"
                          @change="onShowOnlyActualCars($event)"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Ceník dopravného"
                          v-model="deliveryNote.traffic.priceListRegistryId"
                          :items="priceListsRegistry"
                          :return-object="false"
                          item-text="name"
                          item-value="id"
                          :povolenaEditace="formMode != formModes.read"
                          @change="onDistanceChange(null)"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Typ účtování"
                          v-model="deliveryNote.traffic.trafficCharging"
                          :items="trafficChargingOptions"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Typ sortimentu"
                          v-model="deliveryNote.traffic.assortmentType"
                          :items="assortmentTypes"
                          :povolenaEditace="formMode != formModes.read"
                          @change="onDistanceChange(null)"
                      /></v-col>

                      <v-col cols="12" class="py-0"
                        ><InputNumber
                          label="Vzdálenost (KM)"
                          v-model="deliveryNote.traffic.distanceKM"
                          :povolenaEditace="formMode != formModes.read"
                          @input="onDistanceChange"
                          jePovinne
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Checkbox
                          label="Zadat vlastní cenu"
                          v-model="deliveryNote.traffic.customPrice"
                          :povolenaEditace="formMode != formModes.read"
                          @change="onDistanceChange(null)"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><InputNumber
                          label="Celková cena"
                          v-model="deliveryNote.traffic.price"
                          :povolenaEditace="
                            formMode != formModes.read &&
                              deliveryNote.traffic.customPrice
                          "
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><Select
                          label="Auto"
                          v-model="deliveryNote.traffic.carId"
                          :items="cars"
                          item-text="identificator"
                          item-value="id"
                          hledani
                          pridatNezvoleno
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col> </v-row
                  ></v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="tab-additionalCosts"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container style="height:100%;">
                    <v-row dense style="height:100%;">
                      <v-col cols="12">
                        <v-toolbar dense class="v-toolbar-scrollable">
                          <Button
                            tooltip="Založení nového záznamu"
                            @click="showDialogAdditionalCosts(formModes.new)"
                            ><v-icon>mdi-plus</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazení detailu záznamu"
                            @click="showDialogAdditionalCosts(formModes.read)"
                            :povolenaEditace="!!selectedRowAdditionalCosts"
                            ><v-icon>mdi-file-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Změna údajů záznamu"
                            @click="showDialogAdditionalCosts(formModes.edit)"
                            :povolenaEditace="!!selectedRowAdditionalCosts"
                            ><v-icon>mdi-pencil</v-icon></Button
                          >
                          <Button
                            tooltip="Zrušení (zneaktivnění) záznamu"
                            @click="deleteRowAdditionalCosts"
                            :povolenaEditace="
                              !!selectedRowAdditionalCosts &&
                                formMode != formModes.read
                            "
                            ><v-icon>mdi-delete-outline</v-icon></Button
                          >
                          <v-divider vertical class="mx-2"></v-divider>
                          <Button
                            tooltip="Zobrazí nápovědu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-help-circle-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazí tiskovou sestavu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-printer</v-icon></Button
                          >
                        </v-toolbar></v-col
                      >
                      <v-col cols="12" style="height: calc(100% - 56px)">
                        <DataTable
                          :headers="headersAdditionalCosts"
                          :items="deliveryNote.additionalCosts"
                          item-key="id"
                          :selectedItem.sync="selectedRowAdditionalCosts"
                          @dblclick:row="
                            showDialogAdditionalCosts(formModes.read)
                          "
                          :options.sync="optionsAdditionalCosts"
                          :server-items-length="totalRecordsAdditionalCosts"
                          single-select
                          :hide-default-footer="true"
                          @contextmenu:row="showContextMenuAdditionalCosts"
                          height="100%"
                        >
                          <template v-slot:[`item.name`]="{ item }">
                            {{ item.additionalCostsRegistry.name }}
                          </template>
                          <template v-slot:[`item.unitPrice`]="{ item }">
                            {{ item.additionalCostsRegistry.unitPrice }}
                          </template>
                          <template v-slot:[`item.totalPrice`]="{ item }">
                            {{ item.additionalCostsRegistry.unitPrice }}
                          </template>
                        </DataTable>
                        <v-menu
                          v-model="contextMenuAdditionalCostsVisible"
                          :disabled="!selectedRowAdditionalCosts"
                          :position-x="rightClickPositionX"
                          :position-y="rightClickPositiony"
                          absolute
                          offset-y
                        >
                          <v-list dense>
                            <v-list-item
                              @click="showDialogAdditionalCosts(formModes.read)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-file-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Detail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="showDialogAdditionalCosts(formModes.edit)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editace</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="deleteRowAdditionalCosts">
                              <v-list-item-icon>
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Zrušit</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu></v-col
                      ></v-row
                    >
                  </v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="tab-otherCosts"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container style="height:100%;">
                    <v-row dense style="height:100%;">
                      <v-col cols="12">
                        <v-toolbar dense class="v-toolbar-scrollable">
                          <Button
                            tooltip="Založení nového záznamu"
                            @click="showDialogOtherCosts(formModes.new)"
                            ><v-icon>mdi-plus</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazení detailu záznamu"
                            @click="showDialogOtherCosts(formModes.read)"
                            :povolenaEditace="!!selectedRowOtherCosts"
                            ><v-icon>mdi-file-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Změna údajů záznamu"
                            @click="showDialogOtherCosts(formModes.edit)"
                            :povolenaEditace="!!selectedRowOtherCosts"
                            ><v-icon>mdi-pencil</v-icon></Button
                          >
                          <Button
                            tooltip="Zrušení (zneaktivnění) záznamu"
                            @click="deleteRowOtherCosts"
                            :povolenaEditace="
                              !!selectedRowOtherCosts &&
                                formMode != formModes.read
                            "
                            ><v-icon>mdi-delete-outline</v-icon></Button
                          >
                          <v-divider vertical class="mx-2"></v-divider>
                          <Button
                            tooltip="Zobrazí nápovědu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-help-circle-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazí tiskovou sestavu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-printer</v-icon></Button
                          >
                        </v-toolbar></v-col
                      >
                      <v-col cols="12" style="height: calc(100% - 56px)">
                        <DataTable
                          :headers="headersOtherCosts"
                          :items="deliveryNote.otherCosts"
                          item-key="id"
                          :selectedItem.sync="selectedRowOtherCosts"
                          @dblclick:row="showDialogOtherCosts(formModes.read)"
                          :options.sync="optionsOtherCosts"
                          :server-items-length="totalRecordsOtherCosts"
                          single-select
                          :hide-default-footer="true"
                          @contextmenu:row="showContextMenuOtherCosts"
                          height="100%"
                        />
                        <v-menu
                          v-model="contextMenuOtherCostsVisible"
                          :disabled="!selectedRowOtherCosts"
                          :position-x="rightClickPositionX"
                          :position-y="rightClickPositiony"
                          absolute
                          offset-y
                        >
                          <v-list dense>
                            <v-list-item
                              @click="showDialogOtherCosts(formModes.read)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-file-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Detail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="showDialogOtherCosts(formModes.edit)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editace</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="deleteRowOtherCosts">
                              <v-list-item-icon>
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Zrušit</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu></v-col
                      ></v-row
                    >
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row></v-container
      >
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  DeliveryNoteCreateModel,
  DeliveryNoteUpdateModel,
  AssortmentCreateModel,
  AssortmentUpdateModel,
  // GrowingActivityCreateModel,
  // GrowingActivityUpdateModel,
  AdditionalCostsCreateModel,
  AdditionalCostsUpdateModel,
  OtherCostsCreateModel,
  OtherCostsUpdateModel,
  TrafficCreateModel,
  TrafficUpdateModel,
  DeliveryNoteLocalityCreateModel,
  DeliveryNoteLocalityUpdateModel
} from '../store/models/deliveryNoteModels';
import AssortmentDetail from './AssortmentDetail';
import AdditionalCostsDetail from './AdditionalCostsDetail';
import OtherCostsDetail from './OtherCostsDetail';
import dataTableContextMenuMixin from '../mixins/datatableContextMenuMixin';
import { getDifferences } from '../utils/DifferenceHelper';
import { v4 as uuidv4 } from 'uuid';

export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      showOnlyActualPriceLists: true,
      showOnlyActualCars: true,
      formValid: true,
      original: null,
      tab: null,
      companyFrom: null,
      filteredLocalities: [],
      headersAssortment: [
        { text: 'Identifikátor', value: 'identificator' },
        { text: 'Název', value: 'name' },
        { text: 'Množství', value: 'amount' },
        { text: 'Výkupní cena MJ', value: 'unitBuyingPrice' },
        { text: 'Prodejní cena MJ', value: 'unitSellingPrice' }
      ],
      optionsAssortment: {},
      selectedRowAssortment: null,
      totalRecordsAssortment: 0,
      contextMenuAssortmentVisible: false,
      headersAdditionalCosts: [
        { text: 'Název', value: 'name' },
        { text: 'Cena', value: 'unitPrice' },
        { text: 'Množství', value: 'amount' },
        { text: 'Cena celkem', value: 'totalPrice' }
      ],
      optionsAdditionalCosts: {},
      selectedRowAdditionalCosts: null,
      totalRecordsAdditionalCosts: 0,
      contextMenuAdditionalCostsVisible: false,
      headersOtherCosts: [
        { text: 'Název', value: 'name' },
        { text: 'Cena celkem', value: 'price' }
      ],
      optionsOtherCosts: {},
      selectedRowOtherCosts: null,
      totalRecordsOtherCosts: 0,
      contextMenuOtherCostsVisible: false
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: String,
      default: null
    },
    duplicate: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$watch(
      () => this.deliveryNote.assortment,
      () => {
        this.sumPrice();
        this.onDistanceChange();
      }
    );
  },
  async created() {
    this.formMode = this.duplicate ? this.formModes.new : this.formModeProp;
    if (this.formMode == this.formModes.read) {
      this.showOnlyActualPriceLists = false;
      this.showOnlyActualCars = false;
    }
    if (this.idProp) {
      await this.getDeliveryNoteById(this.idProp);
      if (this.duplicate) this.deliveryNote.id = uuidv4();
      await this.verifySuccessCall();
      this.totalRecordsAssortment = this.deliveryNote.assortment.length;
      this.totalRecordsAdditionalCosts = this.deliveryNote.additionalCosts.length;
      this.totalRecordsOtherCosts = this.deliveryNote.otherCosts.length;
      this.$set(
        this.deliveryNote,
        'localities',
        this.deliveryNote.deliveryNotesLocalities.map(el => ({
          id: el.id,
          localityId: el.locality.id,
          name: el.locality.name
        }))
      );
      this.companyFrom = this.deliveryNote?.deliveryNotesLocalities[0]?.locality?.companyId;
    } else {
      this.$set(this.deliveryNote, 'date', this.$moment());
      this.$set(this.deliveryNote.traffic, 'distanceKM', 0);
      this.deliveryNote.id = uuidv4();
    }

    await this.getCompanies();
    await this.verifySuccessCall();

    await this.getLocalities();
    await this.verifySuccessCall();

    await this.getCars({
      all: this.formMode != this.formModes.new
    });
    await this.verifySuccessCall();

    await this.getTrafficTariffRegistry();
    await this.verifySuccessCall();

    await this.getPriceListsRegistry({
      all: this.formMode != this.formModes.new
    });
    await this.verifySuccessCall();

    this.localities = this.localities.map(el => ({
      name: el.name,
      localityId: el.id,
      id:
        this.deliveryNote.deliveryNotesLocalities?.find(
          l => l.localityId == el.id
        ) ?? 0,
      companyId: el.companyId
    }));
    this.onCompanyFromChange();

    this.$refs.form?.resetValidation();
    this.original = this.$_.cloneDeep(this.deliveryNote);
  },
  computed: {
    ...window.get([
      'formModes',
      'company/companies',
      'car/cars',
      'priceListRegistry/priceListsRegistry'
    ]),
    ...window.sync([
      'deliveryNote/deliveryNote',
      'locality/localities',
      'deliveryNote/trafficChargingOptions',
      'deliveryNote/assortmentTypes',
      'trafficTariffRegistry/trafficTariffsRegistry'
    ]),
    formTitle() {
      return `Dodací list - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'deliveryNote/getDeliveryNoteById',
      'deliveryNote/createDeliveryNote',
      'deliveryNote/updateDeliveryNote',
      'deliveryNote/resetDeliveryNoteState',
      'company/getCompanies',
      'locality/getLocalities',
      'additionalCosts/deleteAdditionalCost',
      'assortment/deleteAssortment',
      // 'growingActivity/deleteGrowingActivity',
      'otherCosts/deleteOtherCost',
      'deliveryNoteLocality/deleteDeliveryNoteLocality',
      'car/getCars',
      'trafficTariffRegistry/getTrafficTariffRegistry',
      'priceListRegistry/getPriceListsRegistry'
    ]),
    async onShowOnlyActualPriceLists(e) {
      this.deliveryNote.traffic.priceListRegistryId = null;
      await this.getPriceListsRegistry({ all: !e });
      await this.verifySuccessCall();
    },
    async onShowOnlyActualCars(e) {
      this.deliveryNote.traffic.carId = null;
      await this.getCars({ all: !e });
      await this.verifySuccessCall();
    },
    onCompanyFromChange(clear) {
      if (clear) {
        this.deliveryNote.localities = [];
      }
      this.filteredLocalities = this.localities.filter(
        el => el.companyId === this.companyFrom
      );
    },
    totalAssortmentM() {
      return this.round2Decimals(
        this.$_.sumBy(this.deliveryNote.assortment, 'amount')
      );
    },
    sumPrice() {
      this.deliveryNote.totalBuyingPrice = 0;
      this.deliveryNote.totalSellingPrice = 0;

      this.deliveryNote.assortment.forEach(assortment => {
        this.deliveryNote.totalBuyingPrice +=
          (assortment?.customBuyingPrice ??
            assortment.assortmentRegistry.unitBuyingPrice) * assortment.amount;

        this.deliveryNote.totalSellingPrice +=
          (assortment?.customSellingPrice ??
            assortment.assortmentRegistry.unitSellingPrice) * assortment.amount;
      });
    },
    onDistanceChange(e) {
      let distance;
      if (e) {
        distance = parseInt(e, 10);
      } else {
        distance = this.deliveryNote.traffic.distanceKM;
      }
      const pricePerKM = this.trafficTariffsRegistry.find(
        el =>
          el.from <= distance &&
          el.to >= distance &&
          el.assortmentType === this.deliveryNote.traffic.assortmentType &&
          el.priceListRegistry.id ===
            this.deliveryNote.traffic.priceListRegistryId
      )?.pricePerKM;

      if (pricePerKM && !this.deliveryNote.traffic.customPrice) {
        this.deliveryNote.traffic.price = (
          pricePerKM * this.$_.sumBy(this.deliveryNote.assortment, 'amount')
        ).toFixed(2);
      } else if (this.deliveryNote.traffic.hasOwnProperty('customPrice')) {
        this.deliveryNote.traffic.price =
          distance == 0 && !this.deliveryNote.traffic.customPrice
            ? 0
            : this.deliveryNote.traffic.price ?? 0;
      }
    },
    async close(confirmed) {
      if (!confirmed && this.isDifferent(this.deliveryNote, this.original)) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      await this.resetDeliveryNoteState();
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createDeliveryNote(
          new DeliveryNoteCreateModel(
            this.deliveryNote.id,
            this.deliveryNote.date,
            this.deliveryNote.localities.map(
              el => new DeliveryNoteLocalityCreateModel(el.localityId)
            ),
            this.deliveryNote.sequenceNumber || undefined,
            this.deliveryNote.intentionNumber,
            this.deliveryNote.destinationId,
            this.deliveryNote.totalBuyingPrice,
            this.deliveryNote.totalSellingPrice,
            this.deliveryNote.assortment.map(
              el =>
                new AssortmentCreateModel(
                  el.assortmentRegistryId,
                  el.totalPrice,
                  el.amount,
                  el.customSellingPrice,
                  el.customBuyingPrice
                )
            ),
            this.deliveryNote.growingActivity,
            // .map(
            //   el =>
            //     new GrowingActivityCreateModel(
            //       el.growingActivityRegistryId,
            //       el.amount,
            //       el.totalPrice,
            //       el.dateFrom,
            //       el.dateTo,
            //       el.parentId
            //     )
            // ),
            this.deliveryNote.additionalCosts.map(
              el =>
                new AdditionalCostsCreateModel(
                  el.totalPrice,
                  el.amount,
                  el.additionalCostsRegistryId
                )
            ),
            this.deliveryNote.otherCosts.map(
              el => new OtherCostsCreateModel(el.name, el.price)
            ),
            this.deliveryNote.note,
            this.deliveryNote.traffic &&
              new TrafficCreateModel(
                this.deliveryNote.traffic.distanceKM,
                this.deliveryNote.traffic.price,
                this.deliveryNote.traffic.customPrice,
                this.deliveryNote.traffic.carId,
                this.deliveryNote.traffic.trafficCharging,
                this.deliveryNote.traffic.assortmentType,
                this.deliveryNote.traffic.priceListRegistryId
              )
          )
        );

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Dodací list byl vytvořen.');
          await this.close(true);
        }
      } else {
        await this.updateDeliveryNote(
          new DeliveryNoteUpdateModel(
            this.deliveryNote.id,
            this.deliveryNote.sequenceNumber,
            this.deliveryNote.intentionNumber,
            this.deliveryNote.date,
            this.deliveryNote.localities.map(
              el => new DeliveryNoteLocalityUpdateModel(el.localityId, el.id)
            ),
            this.deliveryNote.destinationId,
            this.deliveryNote.totalBuyingPrice,
            this.deliveryNote.totalSellingPrice,
            this.deliveryNote.assortment.map(
              el =>
                new AssortmentUpdateModel(
                  el.assortmentRegistryId,
                  el.totalPrice,
                  el.amount,
                  el.customSellingPrice,
                  el.customBuyingPrice,
                  el.id
                )
            ),
            this.deliveryNote.growingActivity,
            //.map(
            //   el =>
            //     new GrowingActivityUpdateModel(
            //       el.growingActivityRegistryId,
            //       el.amount,
            //       el.totalPrice,
            //       el.dateFrom,
            //       el.dateTo,
            //       el.parentId,
            //       el.id
            //     )
            //),
            this.deliveryNote.additionalCosts.map(
              el =>
                new AdditionalCostsUpdateModel(
                  el.totalPrice,
                  el.amount,
                  el.additionalCostsRegistryId,
                  el.id
                )
            ),
            this.deliveryNote.otherCosts.map(
              el => new OtherCostsUpdateModel(el.name, el.price, el.id)
            ),
            this.deliveryNote.note,
            this.deliveryNote.traffic &&
              new TrafficUpdateModel(
                this.deliveryNote.traffic.distanceKM,
                this.deliveryNote.traffic.price,
                this.deliveryNote.traffic.customPrice,
                this.deliveryNote.traffic.carId,
                this.deliveryNote.traffic.trafficCharging,
                this.deliveryNote.traffic.assortmentType,
                this.deliveryNote.traffic.priceListRegistryId,
                this.deliveryNote.traffic.id
              )
          )
        );
        const { toDelete: localitiesToDelete } = getDifferences(
          this.original.localities,
          this.deliveryNote.localities
        );
        localitiesToDelete.forEach(
          async el => await this.deleteDeliveryNoteLocality(el)
        );

        const { toDelete: additionalCostsToDelete } = getDifferences(
          this.original.additionalCosts,
          this.deliveryNote.additionalCosts
        );
        additionalCostsToDelete.forEach(
          async el => await this.deleteAdditionalCost(el)
        );

        const { toDelete: assortmentToDelete } = getDifferences(
          this.original.assortment,
          this.deliveryNote.assortment
        );
        assortmentToDelete.forEach(async el => await this.deleteAssortment(el));

        // const { toDelete: growingActivityToDelete } = getDifferences(
        //   this.original.growingActivity,
        //   this.deliveryNote.growingActivity
        // );
        // growingActivityToDelete.forEach(
        //   async el => await this.deleteGrowingActivity(el)
        // );

        const { toDelete: otherCostsToDelete } = getDifferences(
          this.original.otherCosts,
          this.deliveryNote.otherCosts
        );
        otherCostsToDelete.forEach(async el => await this.deleteOtherCost(el));

        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Dodací list byl upraven.');
          await this.close(true);
        }
      }
    },
    async showDialogAssortment(formMode) {
      const dialogResult = await this.$dialog.showAndWait(AssortmentDetail, {
        width: '30rem',
        persistent: true,
        formModeProp: formMode,
        assortmentProp:
          formMode != this.formModes.new
            ? { ...this.selectedRowAssortment }
            : {
                id:
                  this.deliveryNote.assortment.length != 0
                    ? this.$_.min([
                        this.$_.minBy(this.deliveryNote.assortment, 'id').id -
                          1,
                        0
                      ])
                    : 0
              },
        date: this.$moment(this.deliveryNote.date)
          .utc(true)
          .format()
      });
      if (dialogResult.confirmed) {
        if (this.formMode == this.formModes.read) {
          this.formMode = this.formModes.edit;
        }
        if (formMode == this.formModes.new) {
          this.deliveryNote.assortment.push(dialogResult.newAssortment);
          this.totalRecordsAssortment += 1;
          this.$dialog.notify.info('Sortiment byl přidán.');
        } else {
          const index = this.deliveryNote.assortment.indexOf(
            this.deliveryNote.assortment.find(
              el => el.id == dialogResult.newAssortment.id
            )
          );
          this.deliveryNote.assortment.splice(index, 1);
          this.deliveryNote.assortment.splice(
            index,
            0,
            dialogResult.newAssortment
          );
          this.$dialog.notify.info('Sortiment byl upraven.');
        }
      }
    },
    async deleteRowAssortment() {
      this.deliveryNote.assortment = this.deliveryNote.assortment.filter(
        el => el.id != this.selectedRowAssortment.id
      );
      this.totalRecordsAssortment -= 1;
      this.$dialog.notify.info('Sortiment byl odstraněn.');
    },
    showContextMenuAssortment(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuAssortmentVisible = true;
      });
    },
    async showDialogAdditionalCosts(formMode) {
      const dialogResult = await this.$dialog.showAndWait(
        AdditionalCostsDetail,
        {
          width: '30rem',
          persistent: true,
          formModeProp: formMode,
          additionalCostsProp:
            formMode != this.formModes.new
              ? { ...this.selectedRowAdditionalCosts }
              : {
                  id:
                    this.deliveryNote.additionalCosts.length != 0
                      ? this.$_.min([
                          this.$_.minBy(this.deliveryNote.additionalCosts, 'id')
                            .id - 1,
                          0
                        ])
                      : 0
                }
        }
      );
      if (dialogResult.confirmed) {
        if (this.formMode == this.formModes.read) {
          this.formMode = this.formModes.edit;
        }
        if (formMode == this.formModes.new) {
          this.deliveryNote.additionalCosts.push(
            dialogResult.newAdditionalCosts
          );
          this.totalRecordsAdditionalCosts += 1;
          this.$dialog.notify.info('Vícenáklad byl přidán.');
        } else {
          const index = this.deliveryNote.additionalCosts.indexOf(
            this.deliveryNote.additionalCosts.find(
              el => el.id == dialogResult.newAdditionalCosts.id
            )
          );
          this.deliveryNote.additionalCosts.splice(index, 1);
          this.deliveryNote.additionalCosts.splice(
            index,
            0,
            dialogResult.newAdditionalCosts
          );
          this.$dialog.notify.info('Vícenáklad byl upraven.');
        }
      }
    },
    async deleteRowAdditionalCosts() {
      this.deliveryNote.additionalCosts = this.deliveryNote.additionalCosts.filter(
        el => el.id != this.selectedRowAdditionalCosts.id
      );
      this.totalRecordsAdditionalCosts -= 1;
      this.$dialog.notify.info('Vícenáklad byl odstraněn.');
    },
    showContextMenuAdditionalCosts(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuAdditionalCostsVisible = true;
      });
    },
    async showDialogOtherCosts(formMode) {
      const dialogResult = await this.$dialog.showAndWait(OtherCostsDetail, {
        width: '30rem',
        persistent: true,
        formModeProp: formMode,
        otherCostsProp:
          formMode != this.formModes.new
            ? { ...this.selectedRowOtherCosts }
            : {
                id:
                  this.deliveryNote.otherCosts.length != 0
                    ? this.$_.min([
                        this.$_.minBy(this.deliveryNote.otherCosts, 'id').id -
                          1,
                        0
                      ])
                    : 0
              }
      });
      if (dialogResult.confirmed) {
        if (this.formMode == this.formModes.read) {
          this.formMode = this.formModes.edit;
        }
        if (formMode == this.formModes.new) {
          this.deliveryNote.otherCosts.push(dialogResult.newOtherCosts);
          this.totalRecordsOtherCosts += 1;
          this.$dialog.notify.info('Ostatní náklad byl přidán.');
        } else {
          const index = this.deliveryNote.otherCosts.indexOf(
            this.deliveryNote.otherCosts.find(
              el => el.id == dialogResult.newOtherCosts.id
            )
          );
          this.deliveryNote.otherCosts.splice(index, 1);
          this.deliveryNote.otherCosts.splice(
            index,
            0,
            dialogResult.newOtherCosts
          );
          this.$dialog.notify.info('Ostatní náklad byl upraven.');
        }
      }
    },
    async deleteRowOtherCosts() {
      this.deliveryNote.otherCosts = this.deliveryNote.otherCosts.filter(
        el => el.id != this.selectedRowOtherCosts.id
      );
      this.totalRecordsOtherCosts -= 1;
      this.$dialog.notify.info('Ostatní náklad byl odstraněn.');
    },
    showContextMenuOtherCosts(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuOtherCostsVisible = true;
      });
    }
  }
};
</script>

<style></style>
