class PriceListRegistryCreateModel {
  name: string;
  isActive: boolean;
  constructor(name: string, isActive: boolean) {
    this.name = name;
    this.isActive = isActive;
  }
}

class PriceListRegistryUpdateModel {
  name: string;
  isActive: boolean;
  id: number;
  constructor(name: string, id: number, isActive: boolean) {
    this.name = name;
    this.isActive = isActive;
    this.id = id;
  }
}

export { PriceListRegistryCreateModel, PriceListRegistryUpdateModel };
