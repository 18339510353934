/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  localities: [],
  locality: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getLocalities({ commit, rootState }, options) {
    try {
      const { data } = await axios.get('locality/get');
      commit('localities', data);
    } catch (e) {
      commit('localities', rootState.offlineRegistry.localities);
      // error handling is in interceptor
    }
  },
  async getLocalityById({ commit }, id) {
    try {
      const { data } = await axios.get(`locality/get/${id}`);
      commit('locality', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createLocality({}, createLocalityModel) {
    try {
      await axios.post(`locality/create`, createLocalityModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateLocality({}, updateLocalityModel) {
    try {
      await axios.put(`locality/update`, updateLocalityModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteLocality({}, id) {
    try {
      await axios.delete(`locality/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
