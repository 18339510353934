<template>
  <v-container>
    <v-card class="v-card-movable">
      <v-toolbar dense class="v-toolbar-scrollable">
        <Button
          tooltip="Založení nového záznamu"
          @click="showDialog(formModes.new)"
          ><v-icon>mdi-plus</v-icon></Button
        >
        <Button
          tooltip="Zobrazení detailu záznamu"
          @click="showDialog(formModes.read)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-file-outline</v-icon></Button
        >
        <Button
          tooltip="Změna údajů záznamu"
          @click="showDialog(formModes.edit)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-pencil</v-icon></Button
        >
        <Button
          tooltip="Zrušení (zneaktivnění) záznamu"
          @click="deleteRow"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-delete-outline</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button tooltip="Zobrazí nápovědu" :povolenaEditace="false"
          ><v-icon>mdi-help-circle-outline</v-icon></Button
        >
        <Button tooltip="Zobrazí tiskovou sestavu" :povolenaEditace="false"
          ><v-icon>mdi-printer</v-icon></Button
        >
      </v-toolbar>
      <v-container ref="filtry" class="pt-1 pb-3">
        <v-row dense class="d-flex align-center">
          <v-col md="2" cols="12">
            <InputText
              label="Fulltextové vyhledávání"
              v-model="filters.fulltext"
              clearable
            />
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="d-flex justify-end align-self-end ml-auto"
          >
            <Button @click="loadData" color="primary">Načíst</Button>
          </v-col>
        </v-row>
      </v-container>
      <DataTable
        :headers="headers"
        :items="growingActivitesList"
        item-key="id"
        :selectedItem.sync="selectedRow"
        @dblclick:row="showDialog(formModes.read)"
        :options.sync="options"
        :server-items-length="totalRecords"
        single-select
        @contextmenu:row="showContextMenu"
        single-expand
        show-expand
      >
        <template v-slot:[`item.destination`]="{ item }">
          {{ item.destination.name }}
        </template>
        <template v-slot:[`item.localities`]="{ item }">
          {{
            `${item.companyFrom &&
              item.companyFrom.name} - ${item.deliveryNotesLocalities &&
              item.deliveryNotesLocalities
                .map(el => el.locality.name)
                .join(', ')}`
          }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date ? $moment(item.date).format('L') : '' }}
        </template>
        <template v-slot:[`item.assortment`]="{ item }">
          {{
            item.assortment
              ? $_.sumBy(item.assortment, 'amount')
                  .toString()
                  .replace('.', ',')
              : 0
          }}
        </template>
        <template v-slot:[`item.averageBuyingPrice`]="{ item }">
          {{
            Math.round(
              item.assortment.length
                ? item.totalBuyingPrice / $_.sumBy(item.assortment, 'amount')
                : 0
            )
          }}
        </template>
        <template v-slot:[`item.averageSellingPrice`]="{ item }">
          {{
            Math.round(
              item.assortment.length
                ? item.totalSellingPrice / $_.sumBy(item.assortment, 'amount')
                : 0
            )
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <DataTable
              :headers="headersExpanded"
              :items="item.growingActivities"
              item-key="id"
              hide-default-footer
              single-select
              class="pt-1 pb-2"
            >
            </DataTable>
          </td>
        </template>
      </DataTable>
      <v-menu
        v-model="contextMenuVisible"
        :disabled="!selectedRow"
        :position-x="rightClickPositionX"
        :position-y="rightClickPositiony"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="showDialog(formModes.read)">
            <v-list-item-icon>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Detail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="showDialog(formModes.edit)">
            <v-list-item-icon> <v-icon>mdi-pencil</v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Editace</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="deleteRow">
            <v-list-item-icon>
              <v-icon>mdi-delete-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zrušit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card-actions
        ><SelectFromCisButtons :zvolenyZaznam="selectedRow"
      /></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import GrowingActivityDetail from '../components/GrowingActivityDetail.vue';
import dataTableContextMenuMixin from '../mixins/datatableContextMenuMixin';
import selectFromCisButtons from '../mixins/selectFromCisButtons';

export default {
  mixins: [dataTableContextMenuMixin, selectFromCisButtons],
  data() {
    return {
      rows: [],
      headers: [
        { text: 'Zákazník', value: 'customer.name', width: '24%' },
        { text: 'Datum', value: 'date', width: '24%' },
        { text: 'Cena celkem', value: 'totalPrice', width: '24%' },
        { text: 'Poznámka', value: 'note', width: '24%' }
      ],
      options: {},
      selectedRow: null,
      filters: {},
      totalRecords: 0,
      headersExpanded: [
        { text: 'Název', value: 'growingActivityRegistry.name' },
        { text: 'Množství', value: 'totalAmmount', width: '20%' },
        {
          text: 'Cena jednotková',
          value: 'growingActivityRegistry.unitPrice',
          width: '20%'
        }
      ]
    };
  },
  props: {
    isCisModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...window.get([
      'growingActivity/growingActivities',
      'formModes',
      'company/companyList'
    ]),
    growingActivitesList() {
      return this.growingActivities.map(ga => ({
        ...ga,
        customer: this.companyList.find(com => com.id === ga.customerId)
      }));
    }
  },
  async mounted() {
    await this.loadData();
    this.$refs.filtry.addEventListener('keypress', evt => {
      if (evt.keyCode === 13) {
        this.loadData(); // enter
      }
    });
    this.$watch(
      () => this.options,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    ...window.call([
      'company/getCompanyList',
      'growingActivity/getGrowingActivities',
      'growingActivity/deleteGrowingActivity'
    ]),
    async loadData() {
      this.showLoader('Načítání pěstebních činností...');
      await this.getCompanyList();
      await this.verifySuccessCall();
      await this.getGrowingActivities({ ...this.filters, companyList: this.companyList });
      await this.verifySuccessCall();

      this.totalRecords = this.growingActivities.length;
      this.selectedRow = null;
      this.hideLoader();
    },
    async showDialog(formMode) {
      const dialogResult = await this.$dialog.showAndWait(
        GrowingActivityDetail,
        {
          width: '60rem',
          persistent: true,
          formModeProp: formMode,
          idProp: formMode === this.formModes.new ? null : this.selectedRow.id
        }
      );
      if (dialogResult.confirmed) {
        this.loadData();
      }
    },
    async deleteRow() {
      if (!(await this.confirmDelete())) {
        return;
      }
      this.showLoader('Probíhá zneaktivnění záznamu...');

      await this.deleteGrowingActivity(this.selectedRow.id);

      this.hideLoader();
      if (await this.verifySuccessCall()) {
        this.$dialog.notify.info('Zneaktivnění bylo úspěšné.');
      }
      await this.loadData();
    }
  }
};
</script>
