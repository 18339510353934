<template>
  <v-checkbox
    v-bind="computedProps"
    v-on="$listeners"
    class=" ma-0 pa-0"
    v-model="computedValue"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"/></template
  ></v-checkbox>
</template>

<script>
import { VCheckbox } from 'vuetify/lib';

export default {
  extends: VCheckbox,
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      return props;
    },
    computedValue: {
      get() {
        return (
          this.inputValue === 'true' ||
          (this.inputValue === 'false' ? false : this.inputValue)
        );
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  }
};
</script>
