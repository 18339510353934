<template>
  <div>
    <v-tooltip top :disabled="!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="{ ...attrs }" v-on="{ ...on }">
          <v-btn-toggle
            v-bind="{ ...computedProps }"
            v-on="{ ...$listeners }"
            style="min-width:36px; padding:0 5px"
            class="mx-1"
            mandatory
          >
            <!-- Pass on all named slots -->
            <slot
              v-for="slot in Object.keys($slots)"
              :name="slot"
              :slot="slot"
            />
            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($scopedSlots)"
              :slot="slot"
              slot-scope="scope"
              ><slot :name="slot" v-bind="scope"
            /></template>
          </v-btn-toggle>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VRadioGroup } from 'vuetify/lib';

export default {
  extends: VRadioGroup,
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      return props;
    }
  }
};
</script>
