import { LocalityUpdateModel } from './localitiesModels';
import { ContactModel } from './contactModels';
class CompanyCreateModel {
  name: string;
  in: string;
  tin: string;
  city: string;
  street: string;
  zipCode: string;
  note: string;
  localities: Array<LocalityUpdateModel>;
  contacts: Array<ContactModel>;
  bankAccount: string;
  constructor(
    name: string,
    _in: string,
    tin: string,
    city: string,
    street: string,
    zipCode: string,
    note: string,
    localities: Array<LocalityUpdateModel>,
    contacts: Array<ContactModel>,
    bankAccount: string
  ) {
    this.name = name;
    this.in = _in ?? null;
    this.tin = tin ?? null;
    this.city = city;
    this.street = street;
    this.zipCode = zipCode;
    this.note = note ?? null;
    this.localities = localities ?? null;
    this.contacts = contacts ?? null;
    this.bankAccount = bankAccount;
  }
}

class CompanyUpdateModel {
  name: string;
  in: string;
  tin: string;
  city: string;
  street: string;
  zipCode: string;
  note: string;
  localities: Array<LocalityUpdateModel>;
  contacts: Array<ContactModel>;
  bankAccount: string;
  id: number;
  constructor(
    name: string,
    _in: string,
    tin: string,
    city: string,
    street: string,
    zipCode: string,
    note: string,
    localities: Array<LocalityUpdateModel>,
    contacts: Array<ContactModel>,
    bankAccount: string,
    id: number
  ) {
    this.name = name;
    this.in = _in ?? null;
    this.tin = tin ?? null;
    this.city = city;
    this.street = street;
    this.zipCode = zipCode;
    this.note = note ?? null;
    this.localities = localities ?? null;
    this.contacts = contacts ?? null;
    this.bankAccount = bankAccount;
    this.id = id;
  }
}

export {
  CompanyCreateModel,
  CompanyUpdateModel,
  LocalityUpdateModel,
  ContactModel
};
