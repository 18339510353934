/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  userInfo: null
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getUserInfo({ commit }) {
    try {
      const response = await axios.get('manage/userInfo');
      commit('userInfo', response.data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async changePassword({}, changePasswordModel) {
    try {
      await axios.post('manage/change/Password', changePasswordModel);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
