<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Počet dodacích listů</v-card-title>
          <BarChart
            ref="deliveryNotesChart"
            :chartData="deliveryNoteChartData"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Prodáno/Vykoupeno m³</v-card-title>
          <BarChart
            ref="deliveryNotesAssortmentSumChart"
            :chartData="deliveryNoteAssortmentSumChartData"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title
            >Stav aut
            <span
              class="pl-1"
              v-if="dashboardCars.length"
              style="color:var(--v-error-base)"
            >
              - vyžadována pozornost</span
            >
          </v-card-title>
          <DataTable
            :headers="carsHeaders"
            :items="dashboardCars"
            item-key="id"
            single-select
            :hide-default-footer="true"
            no-data-text="Žádnému autu se neblíží termín kontroly."
          >
            <template v-slot:[`item.technicalInspectionDate`]="{ item }">
              {{
                item.technicalInspectionDate
                  ? $moment(item.technicalInspectionDate).format('L')
                  : ''
              }}
            </template>
            <template v-slot:[`item.revisionDate`]="{ item }">
              {{
                item.revisionDate ? $moment(item.revisionDate).format('L') : ''
              }}
            </template>
            <template v-slot:[`item.excessiveDate`]="{ item }">
              {{
                item.excessiveDate
                  ? $moment(item.excessiveDate).format('L')
                  : ''
              }}
            </template>
          </DataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      carsHeaders: [
        { text: 'SPZ', value: 'identificator' },
        { text: 'STK', value: 'technicalInspectionDate' },
        { text: 'Revize', value: 'revisionDate' },
        { text: 'Nadměry', value: 'excessiveDate' }
      ],
      deliveryNoteChartData: {
        labels: this.$moment.months(),
        datasets: [
          {
            backgroundColor: '#084f02',
            hoverBackgroundColor: '#29681c'
          }
        ]
      },
      deliveryNoteAssortmentSumChartData: {
        labels: this.$moment.months(),
        datasets: [
          {
            backgroundColor: '#084f02',
            hoverBackgroundColor: '#29681c'
          }
        ]
      }
    };
  },
  computed: {
    ...window.get([
      'dashboard/dashboardCars',
      'dashboard/dashboardDeliveryNotes',
      'dashboard/dashboardDeliveryNotesAssortmentSum'
    ])
  },
  async mounted() {
    await this.loadData();

    this.deliveryNoteChartData.datasets[0].data = this.$moment
      .months()
      .map((m, i) => {
        const index = i + 1;
        return (
          this.dashboardDeliveryNotes.find(el => el.month == index)?.count ?? 0
        );
      });
    this.$refs.deliveryNotesChart.update();

    this.deliveryNoteAssortmentSumChartData.datasets[0].data = this.$moment
      .months()
      .map((m, i) => {
        const index = i + 1;
        return (
          this.dashboardDeliveryNotesAssortmentSum.find(el => el.month == index)
            ?.count ?? 0
        );
      });
    this.$refs.deliveryNotesAssortmentSumChart.update();
  },
  methods: {
    ...window.call([
      'dashboard/getDashboardCars',
      'dashboard/getDashboardDeliveryNotes',
      'dashboard/getDashboardDeliveryNotesAssortmentSum'
    ]),
    async loadData() {
      this.showLoader('Načítání přehledu...');

      await this.getDashboardCars();
      await this.getDashboardDeliveryNotes();
      await this.getDashboardDeliveryNotesAssortmentSum();

      this.hideLoader();
    }
  }
};
</script>
