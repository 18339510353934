<template>
  <v-container>
    <v-card class="v-card-movable">
      <v-card-title v-if="isCisModal">Výběr pěstební činnosti</v-card-title>
      <v-toolbar dense class="v-toolbar-scrollable">
        <Button
          tooltip="Založení nového záznamu"
          @click="showDialog(formModes.new)"
          ><v-icon>mdi-plus</v-icon></Button
        >
        <Button
          tooltip="Zobrazení detailu záznamu"
          @click="showDialog(formModes.read)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-file-outline</v-icon></Button
        >
        <Button
          tooltip="Změna údajů záznamu"
          @click="showDialog(formModes.edit)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-pencil</v-icon></Button
        >
        <Button
          tooltip="Zrušení (zneaktivnění) záznamu"
          @click="deleteRow"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-delete-outline</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button
          tooltip="Změna zobrazení číselníku"
          @click="showTreeview = !showTreeview"
          ><v-icon>mdi-file-tree-outline</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button tooltip="Zobrazí nápovědu" :povolenaEditace="false"
          ><v-icon>mdi-help-circle-outline</v-icon></Button
        >
        <Button tooltip="Zobrazí tiskovou sestavu" :povolenaEditace="false"
          ><v-icon>mdi-printer</v-icon></Button
        >
      </v-toolbar>
      <v-container ref="filtry" class="pt-1 pb-3">
        <v-row dense class="d-flex align-center">
          <v-col md="2" cols="12">
            <InputText
              label="Fulltextové vyhledávání"
              v-model="filters.fulltext"
              clearable
            />
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="d-flex justify-end align-self-end ml-auto"
          >
            <Button @click="loadData" color="primary">Načíst</Button>
          </v-col>
        </v-row>
      </v-container>
      <DataTable
        v-if="!showTreeview"
        :headers="headers"
        :items="growingActivitiesRegistry"
        item-key="id"
        :selectedItem.sync="selectedRow"
        @dblclick:row="showDialog(formModes.read)"
        :options.sync="options"
        :server-items-length="totalRecords"
        single-select
        @contextmenu:row="showContextMenu"
      >
        <template v-slot:[`item.dateFrom`]="{ item }">
          {{ item.dateFrom ? $moment(item.dateFrom).format('L') : '' }}
        </template>
        <template v-slot:[`item.dateTo`]="{ item }">
          {{ item.dateTo ? $moment(item.dateTo).format('L') : '' }}
        </template>
        <template v-slot:[`item.parent`]="{ item }">
          {{ getParentName(item) }}
        </template>
      </DataTable>
      <TreeView
        v-else
        :items="growingActivitiesRegistry"
        parentId="parentId"
        item-key="id"
        item-text="name"
        :selectedItem.sync="selectedRow"
      >
        <template v-slot:label="{ item }">
          <span class="d-flex flex-row">
            {{ item.name }}
          </span>
        </template>
      </TreeView>
      <v-menu
        v-model="contextMenuVisible"
        :disabled="!selectedRow"
        :position-x="rightClickPositionX"
        :position-y="rightClickPositiony"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="showDialog(formModes.read)">
            <v-list-item-icon>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Detail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="showDialog(formModes.edit)">
            <v-list-item-icon> <v-icon>mdi-pencil</v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Editace</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="deleteRow">
            <v-list-item-icon>
              <v-icon>mdi-delete-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zrušit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card-actions
        ><SelectFromCisButtons :zvolenyZaznam="selectedRow"
      /></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import GrowingActivityRegistryDetail from '../../components/Registry/GrowingActivityRegistryDetail';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
import selectFromCisButtons from '../../mixins/selectFromCisButtons';

export default {
  mixins: [dataTableContextMenuMixin, selectFromCisButtons],
  data() {
    return {
      rows: [],
      headers: [
        // { text: 'Identifikátor', value: 'identificator', width: '15%' },
        { text: 'Název', value: 'name', width: '20%' },
        { text: 'Nadřazená položka', value: 'parent' },
        { text: 'Platnost od', value: 'dateFrom', width: '10%' },
        { text: 'Platnost do', value: 'dateTo', width: '10%' }
      ],
      options: {},
      selectedRow: null,
      filters: {},
      totalRecords: 0,
      showTreeview: true
    };
  },
  props: {
    isCisModal: {
      type: Boolean,
      default: false
    },
    filterDate: {
      type: String,
      default: null
    }
  },
  computed: {
    ...window.get([
      'growingActivityRegistry/growingActivitiesRegistry',
      'growingActivityRegistry/growingActivityRegistry',
      'formModes'
    ])
  },
  async mounted() {
    await this.loadData();
    this.$refs.filtry.addEventListener('keypress', evt => {
      if (evt.keyCode === 13) {
        this.loadData(); // enter
      }
    });
    this.$watch(
      () => this.options,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    ...window.call([
      'growingActivityRegistry/getGrowingActivitiesRegistry',
      'growingActivityRegistry/deleteGrowingActivityRegistry'
    ]),
    async loadData() {
      this.showLoader('Načítání číselníku...');

      await this.getGrowingActivitiesRegistry({
        ...this.filters,
        ...this.options,
        enablePaging: false,
        date: this.filterDate
      });

      this.totalRecords = this.growingActivitiesRegistry.length;
      this.selectedRow = null;
      this.hideLoader();
    },
    async showDialog(formMode) {
      const dialogResult = await this.$dialog.showAndWait(
        GrowingActivityRegistryDetail,
        {
          width: '40rem',
          persistent: true,
          formModeProp: formMode,
          idProp:
            formMode === this.formModes.new
              ? null
              : parseInt(this.selectedRow.id, 10)
        }
      );
      if (dialogResult.confirmed) {
        this.loadData();
      }
    },
    async deleteRow() {
      if (!(await this.confirmDelete())) {
        return;
      }
      this.showLoader('Probíhá zneaktivnění záznamu...');

      await this.deleteGrowingActivityRegistry(this.selectedRow.id);

      this.hideLoader();
      if (await this.verifySuccessCall()) {
        this.$dialog.notify.info('Zneaktivnění bylo úspěšné.');
      }
      await this.loadData();
    },
    getParentName(growingActivity) {
      const parents = [];

      let item = this.growingActivitiesRegistry.find(
        el => el.id == growingActivity.parentId
      );

      while (item) {
        parents.push(item.name);
        item = this.growingActivitiesRegistry.find(
          el => el.id == item.parentId
        );
      }
      return parents.reverse().join(' > ');
    }
  },
  watch: {
    selectedRow(newValue) {
      if (newValue?.id == this.growingActivityRegistry.id) {
        this.selectedRow = null;
      }
    }
  }
};
</script>
