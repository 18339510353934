/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import axios from '../plugins/axios';
import { make } from 'vuex-pathify';

const state = {
  companies: null,
  localities: null,
  cars: null,
  trafficTariffsRegistry: null,
  priceListsRegistry: null,
  assortmentsRegistry: null
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getData({ commit, rootState }) {
    try {
      const { data: companies } = await axios.get('company/get');
      commit('companies', companies);

      const { data: localities } = await axios.get('locality/get');
      commit('localities', localities);

      const { data: cars } = await axios.get('car/get');
      commit('cars', cars);

      let { data: trafficTariffRegistry } = await axios.get(
        'trafficTariffRegistry/get'
      );
      const assortmentTypes = rootState.deliveryNote.assortmentTypes;

      trafficTariffRegistry = trafficTariffRegistry.map(el => ({
        ...el,
        assortmentTypeName: assortmentTypes.find(
          at => at.key === el.assortmentType
        ).value
      }));
      commit('trafficTariffsRegistry', trafficTariffRegistry);

      const { data: priceListsRegistry } = await axios.get(
        'priceListRegistry/get'
      );
      commit('priceListsRegistry', priceListsRegistry);

      const { data: assortmentsRegistry } = await axios.get(
        'assortmentRegistry/get'
      );
      commit('assortmentsRegistry', assortmentsRegistry);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
