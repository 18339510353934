import _ from 'lodash';
import moment from 'moment';

export { filterOrderGetPage };

function filterOrderGetPage(
  array,
  options = null,
  fulltextFilterPropertyName = 'fulltext'
) {
  return options
    ? filterPage(
        orderArray(
          filterByValue(array, options[fulltextFilterPropertyName]),
          options
        ),
        options
      )
    : array;
}

function filterByValue(array, string) {
  if (!string) {
    return array;
  }
  return array.filter(o =>
    Object.keys(o).some(
      k =>
        (k &&
          o[k] &&
          o[k]
            .toString()
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .includes(
              string
                .toLowerCase()
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')
            )) ||
        (moment(o[k], moment.ISO_8601, true).isValid() &&
          moment(o[k], moment.ISO_8601)
            .format('L')
            .includes(string))
    )
  );
}

function filterPage(array, options) {
  if (options.enablePaging) {
    return _.slice(
      array,
      (options.page - 1) * options.itemsPerPage,
      options.page * options.itemsPerPage
    );
  } else {
    return array;
  }
}

function orderArray(array, options) {
  if (options.sortBy) {
    return _.orderBy(
      array,
      options.sortBy[0],
      options.sortDesc[0] == true ? 'desc' : 'asc'
    );
  } else return array;
}
