/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '@/utils/TableFilterHelper';

const state = {
  growingActivities: [],
  growingActivity: {
    growingActivities: []
  }
};
const getters = { ...make.getters(state) };
const actions = {
  async getGrowingActivities({ commit }, options) {
    try {
      const { data } = await axios.get('growingActivity/get');

      const joinedData = data.map(el => ({
        ...el,
        customerName: options?.companyList?.find(f => f.id == el.customerId)?.name
      }));
      const filteredData = filterOrderGetPage(joinedData, options);
      commit('growingActivities', filteredData);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getGrowingActivityById({ commit }, id) {
    try {
      const { data } = await axios.get(`growingActivity/get/${id}`);
      commit('growingActivity', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createGrowingActivity({}, createGrowingActivity) {
    try {
      await axios.post(`growingActivity/create`, createGrowingActivity);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateGrowingActivity({}, updateGrowingActivity) {
    try {
      await axios.put(`growingActivity/update`, updateGrowingActivity);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteGrowingActivity({}, id) {
    try {
      await axios.delete(`growingActivity/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
