<template>
  <v-app>
    <v-container class="fill-height">
      <v-layout wrap>
        <v-flex
          sm12
          md8
          offset-md2
          lg6
          offset-lg3
          xl4
          offset-xl4
          class="d-flex flex-column justify-center "
        >
          <v-card elevation="4" tag="section">
            <v-card-title class="d-flex">
              <img
                :alt="platformName"
                :src="logo"
                :height="$vuetify.breakpoint.xs ? 40 : 60"
                class="pr-3"
              />
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mb-2 mt-1">
              <p class="body-1" v-if="requestValid">
                Změna uživatelského hesla: {{ email }}
              </p>
              <p class="pb-4" v-if="requestValid">
                Zadejte nové heslo a poté ještě jednou pro potvrzení:
              </p>
              <p v-else style="color:red;">
                Token pro změnu hesla nebyl nalezen, zažádejte znovu o jeho
                zaslání emailem a použijte odkaz, který vám přijde do schránky.
              </p>
              <v-form ref="formInput" v-model="formValid" lazy-validation>
                <InputText
                  persistent-hint
                  hint="Nové heslo"
                  v-model="password"
                  jePovinne
                  :type="passwordVisible ? 'text' : 'password'"
                  autocomplete="off"
                  :append-icon="
                    passwordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="passwordVisible = !passwordVisible"
                  :povolenaEditace="requestValid"
                />
                <InputText
                  persistent-hint
                  hint="Nové heslo znovu"
                  v-model="repeatPassword"
                  @keydown="passwordKeydown"
                  :type="passwordRepeatVisible ? 'text' : 'password'"
                  :append-icon="
                    passwordRepeatVisible
                      ? 'mdi-eye-outline'
                      : 'mdi-eye-off-outline'
                  "
                  @click:append="passwordRepeatVisible = !passwordRepeatVisible"
                  jePovinne
                  autocomplete="off"
                  :povolenaEditace="requestValid"
                />
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
              <v-row
                ><v-col cols="12" md="6">
                  <Button color="primary" text @click="redirectToLogin"
                    >Zpět na přihlášení</Button
                  > </v-col
                ><v-col cols="12" md="6">
                  <Button
                    color="primary"
                    :large="$vuetify.breakpoint.smAndUp"
                    :povolenaEditace="formValid && requestValid"
                    :stylesObject="{ width: '100%' }"
                    @click="setNewPassword"
                  >
                    <v-icon left>mdi-lock</v-icon>Potvrdit nové heslo</Button
                  ></v-col
                ></v-row
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { ResetPasswordModel } from '@/store/models/authModels';
/* eslint-disable no-undef */

export default {
  name: 'Login',
  data() {
    return {
      platformName: 'ForestCare',
      password: null,
      repeatPassword: null,
      formValid: true,
      passwordVisible: false,
      passwordRepeatVisible: false,
      token: null,
      email: null
    };
  },
  created() {
    this.token = this.$route.query?.token ?? null;
    this.email = this.$route.query?.email ?? null;
  },
  computed: {
    logo() {
      return require('../assets/forestcare_nizke.png');
    },
    requestValid() {
      return !!this.token && !!this.email;
    }
  },
  methods: {
    ...window.call(['auth/loginUser', 'auth/resetPassword']),
    redirectToLogin() {
      this.$router.push({ path: '/loginRedirect' });
    },
    async setNewPassword() {
      if (!this.$refs.formInput.validate()) {
        return;
      }

      if (this.password !== this.repeatPassword) {
        return this.$dialog.notify.error('Zadaná hesla se neshodují!');
      }

      await this.resetPassword(
        new ResetPasswordModel(this.email, this.token, this.password)
      );
      if (await this.verifySuccessCall()) {
        await this.$dialog.confirm({
          text: 'Heslo bylo úspěšně změněno.',
          title: 'Hotovo',
          actions: [
            {
              text: 'Zavřít',
              key: true
            }
          ]
        });
        this.$router.push({ path: '/loginRedirect' });
      }
    },
    async passwordKeydown(e) {
      if (e.keyCode == 13) {
        await this.setNewPassword();
      }
    }
  }
};
</script>
