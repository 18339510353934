class ContactModel {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  fax: string;
  constructor(
    id: number,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    fax: string
  ) {
    this.id = id < 0 ? 0 : id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.phone = phone;
    this.fax = fax;
  }
}

// todo ostatni modely, az budou spravne na sluzbe

export { ContactModel };
