/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  roles: [],
  appRoles: {
    administrator: 'Administrator',
    grower: 'Pěstitel',
    driver: 'Řidič'
  }
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getRoles({ commit }, options) {
    try {
      const { data } = await axios.get('role/get');
      commit('roles', data);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
