<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <v-col cols="12" class="py-0"
            ><InputText
              label="SPZ"
              v-model="car.identificator"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><InputText
              label="VIN"
              v-model="car.vin"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><DatePicker
              label="STK"
              v-model="car.technicalInspectionDate"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><DatePicker
              label="Revize"
              v-model="car.revisionDate"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><DatePicker
              label="Nadměry"
              v-model="car.excessiveDate"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0" v-if="formMode != formModes.new"
            ><DatePicker
              label="Smazáno"
              v-model="car.deleted"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><Select
              label="Uživatel"
              v-model="car.user"
              :items="users"
              :item-text="u => `${u.firstname} ${u.lastname}`"
              item-value="id"
              hledani
              :return-object="true"
              :povolenaEditace="formMode != formModes.read"/></v-col></v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import { CarCreateModel, CarUpdateModel } from '../store/models/carModels';
export default {
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getCarById(this.idProp);
      await this.verifySuccessCall();
    }
    await this.getUsers();
    await this.verifySuccessCall();
    this.original = this.$_.cloneDeep(this.car);
  },
  computed: {
    ...window.get(['user/users', 'formModes']),
    ...window.sync(['car/car']),
    formTitle() {
      return `Auto - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'user/getUsers',
      'car/getCarById',
      'car/createCar',
      'car/updateCar'
    ]),
    async close(confirmed) {
      if (!confirmed && this.isDifferent(this.car, this.original)) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.car = {};
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createCar(
          new CarCreateModel(
            this.car.identificator,
            this.car.technicalInspectionDate,
            this.car.revisionDate,
            this.car.excessiveDate,
            this.car.vin,
            this.car.user.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Auto bylo vytvořeno.');
          await this.close(true);
        }
      } else {
        await this.updateCar(
          new CarUpdateModel(
            this.car.identificator,
            this.car.technicalInspectionDate,
            this.car.revisionDate,
            this.car.excessiveDate,
            this.car.vin,
            this.car.user.id,
            this.car.id,
            this.car.deleted
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Auto bylo upraveno.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
