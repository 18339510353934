/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const state = {
  additionalCostsRegistry: [],
  additionalCostRegistry: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getAdditionalCostsRegistry({ commit }, filterOptions) {
    try {
      const { data } = await axios.get('additionalCostsRegistry/get', {
        params: { date: filterOptions?.date ?? null }
      });
      const filteredData = filterOrderGetPage(data, filterOptions);
      commit('additionalCostsRegistry', filteredData);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getAdditionalCostsRegistryById({ commit }, id) {
    try {
      const { data } = await axios.get(`additionalCostsRegistry/get/${id}`);
      commit('additionalCostRegistry', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createAdditionalCostsRegistry({}, createAdditionalCostRegistryModel) {
    try {
      await axios.post(
        `additionalCostsRegistry/create`,
        createAdditionalCostRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateAdditionalCostsRegistry({}, updateAdditionalCostRegistryModel) {
    try {
      await axios.put(
        `additionalCostsRegistry/update`,
        updateAdditionalCostRegistryModel
      );
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteAdditionalCostsRegistry({}, id) {
    try {
      await axios.delete(`additionalCostsRegistry/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
