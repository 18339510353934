<template>
  <span class="d-flex flex-row mt-3" v-if="fromCisBox">
    <Button @click="zvolitZaznamZCiselniku"
      ><v-icon>mdi-check</v-icon> Zvolit</Button
    >
    <Button @click="zavritSelectFromCis">
      <v-icon>mdi-close</v-icon>
      Zavřít</Button
    >
  </span>
</template>

<script>
export default {
  data() {
    return {
      fromCisBox: false,
      parentComponent: null
    };
  },
  props: {
    zvolenyZaznam: {
      type: Object,
      default: null
    }
  },
  computed: {
    zvoleno() {
      return this.zvolenyZaznam != null;
    }
  },
  mounted() {
    this.findCisBoxProp(this.$root);
  },
  methods: {
    findCisBoxProp(component) {
      if (component.isCisModal === undefined) {
        if (component.$children?.length > 0) {
          this.findCisBoxProp(component.$children[0]);
        }
      } else {
        this.fromCisBox = component.isCisModal;
        this.parentComponent = component;
      }
    },
    zavritSelectFromCis() {
      this.parentComponent.$emit('submit', null);
    },
    zvolitZaznamZCiselniku() {
      this.parentComponent.$emit('submit', { value: this.zvolenyZaznam });
    }
  }
};
</script>
