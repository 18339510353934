// LOCAL FILES
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Select from './components/Controls/Select.vue';
import Checkbox from './components/Controls/Checkbox.vue';
import InputText from './components/Controls/InputText.vue';
import InputNumber from './components/Controls/InputNumber.vue';
import DatePicker from './components/Controls/DatePicker.vue';
import TimePicker from './components/Controls/TimePicker.vue';
import DataTable from './components/Controls/DataTable.vue';
import TreeView from './components/Controls/TreeView.vue';
import Button from './components/Controls/Button.vue';
import RadioGroup from './components/Controls/RadioGroup.vue';
import Textarea from './components/Controls/Textarea.vue';
import CisBox from './components/Controls/CisBox.vue';
import InputMask from './components/Controls/InputMask.vue';
import BarChart from './components/Controls/BarChart.vue';
// PACKAGES
import Vue from 'vue';
import VuetifyDialog from 'vuetify-dialog';
import { get, sync, call } from 'vuex-pathify';
import _ from 'lodash';
import * as moment from 'moment';
import VuetifyMask from 'vuetify-mask';
// MIXINS
import dialogMixin from './mixins/dialog';
import globalUtilsMixin from './mixins/globalUtils';
import loadingComponent from './mixins/loadingComponent';
import isDifferentMixin from './mixins/isDifferentMixin';
import loadDataMixin from './mixins/loadDataMixin';
// STYLES
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify-dialog/dist/vuetify-dialog.css';
import './styles/animations.scss';
import './styles/overrides.scss';
import './styles/colors.scss';
import 'moment/locale/cs';
// OTHERS
import './registerServiceWorker';
// import wb from './registerServiceWorker';
// Vue.prototype.$workbox = wb;
// COMPONENTS
Vue.component('DialogMixin', dialogMixin);
Vue.component('GlobalUtilsMixin', globalUtilsMixin);
Vue.component('IsDifferentMixin', isDifferentMixin);
Vue.component('LoadingComponent', loadingComponent);
Vue.component('LoadDataMixin', loadDataMixin);
Vue.component('Textarea', Textarea);
Vue.component('RadioGroup', RadioGroup);
Vue.component('Button', Button);
Vue.component('DataTable', DataTable);
Vue.component('TreeView', TreeView);
Vue.component('Select', Select);
Vue.component('Checkbox', Checkbox);
Vue.component('TimePicker', TimePicker);
Vue.component('DatePicker', DatePicker);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('CisBox', CisBox);
Vue.component('InputMask', InputMask);
Vue.component('BarChart', BarChart);
// PLUGINS
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store
  },
  notification: { position: 'bottom-right' },
  confirm: {
    actions: {
      false: 'Ne',
      true: 'Ano'
    }
  }
});
Vue.use(VuetifyMask);
// PROTOTYPES
Object.defineProperty(Vue.prototype, '$_', { value: _ });
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

declare global {
  interface Window {
    get: any;
    sync: any;
    call: any;
  }
}

window.get = get;
window.sync = sync;
window.call = call;

Vue.config.productionTip = false;

const axios = require('axios');

axios.get('config.json').then((res, err) => {
  if (!err) {
    store.dispatch('Configure', res.data);
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
});
