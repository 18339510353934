/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const state = {
  cars: [],
  car: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getCars({ commit, rootState }, filterOptions) {
    try {
      const { data } = await axios.get('car/get', {
        params: filterOptions
      });
      const filteredData = filterOrderGetPage(data, filterOptions ?? null);
      commit('cars', filteredData);
    } catch (e) {
      commit('cars', rootState.offlineRegistry.cars);
      // error handling is in interceptor
    }
  },
  async getCarById({ commit }, id) {
    try {
      const { data } = await axios.get(`car/get/${id}`);
      if (!data.user) {
        data.user = {};
      }
      commit('car', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createCar({}, createCarModel) {
    try {
      await axios.post(`car/create`, createCarModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateCar({}, updateCarModel) {
    try {
      await axios.put(`car/update`, updateCarModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteCar({}, id) {
    try {
      await axios.delete(`car/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
