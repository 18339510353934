<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <v-col cols="12" class="py-0"
            ><InputText
              label="Identifikátor"
              v-model="assortmentRegistry.identificator"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><InputText
              label="Název"
              v-model="assortmentRegistry.name"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Kupní cena MJ"
              v-model="assortmentRegistry.unitBuyingPrice"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Prodejní cena MJ"
              v-model="assortmentRegistry.unitSellingPrice"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <DatePicker
              label="Platnost od"
              v-model="assortmentRegistry.dateFrom"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            /> </v-col
          ><v-col cols="12" class="py-0">
            <DatePicker
              label="Platnost do"
              v-model="assortmentRegistry.dateTo"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  AssortmentRegistryCreateModel,
  AssortmentRegistryUpdateModel
} from '../../store/models/assortmentRegistryModels';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getAssortmentRegistryById(this.idProp);
      await this.verifySuccessCall();
    }
    this.original = this.$_.cloneDeep(this.assortmentRegistry);
  },
  computed: {
    ...window.get(['formModes']),
    ...window.sync(['assortmentRegistry/assortmentRegistry']),
    formTitle() {
      return `Sortiment - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'assortmentRegistry/getAssortmentRegistryById',
      'assortmentRegistry/createAssortmentRegistry',
      'assortmentRegistry/updateAssortmentRegistry'
    ]),
    async close(confirmed) {
      if (
        !confirmed &&
        this.isDifferent(this.assortmentRegistry, this.original)
      ) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.assortmentRegistry = {};
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createAssortmentRegistry(
          new AssortmentRegistryCreateModel(
            this.assortmentRegistry.identificator,
            this.assortmentRegistry.name,
            this.assortmentRegistry.unitBuyingPrice,
            this.assortmentRegistry.unitSellingPrice,
            this.assortmentRegistry.dateFrom,
            this.assortmentRegistry.dateTo
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Sortiment byl vytvořen.');
          await this.close(true);
        }
      } else {
        await this.updateAssortmentRegistry(
          new AssortmentRegistryUpdateModel(
            this.assortmentRegistry.identificator,
            this.assortmentRegistry.name,
            this.assortmentRegistry.unitBuyingPrice,
            this.assortmentRegistry.unitSellingPrice,
            this.assortmentRegistry.dateFrom,
            this.assortmentRegistry.dateTo,
            this.assortmentRegistry.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Sortiment byl upraven.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
