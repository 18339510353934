/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { commit, make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';

const state = {
  users: [],
  user: {}
};
const getters = {
  ...make.getters(state),
  userIsInRole: (state, getters, rootState) => role => {
    return rootState.auth.userRoles?.includes(role);
  }
};
const actions = {
  ...make.actions(state),
  async getUsers({ commit }, filterOptions) {
    try {
      const { data } = await axios.get('user/get');
      const filteredData = filterOrderGetPage(data, filterOptions);
      commit('users', filteredData);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getUserById({ commit }, id) {
    try {
      const { data } = await axios.get(`user/get/${id}`);
      commit('user', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },

  async disableUserById({}, id) {
    try {
      await axios.delete(`user/disable/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createUser({ commit, state }, createUserModel) {
    try {
      const { data } = await axios.post(`user/create`, createUserModel);
      commit('user', { ...state.user, id: data.id });
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateUser({}, updateUserModel) {
    try {
      await axios.put(`user/update`, updateUserModel);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
