<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense>
          <v-col cols="12" class="py-0"
            ><InputText
              label="Název"
              v-model="additionalCostRegistry.name"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0">
            <InputNumber
              label="Cena za m³"
              v-model="additionalCostRegistry.unitPrice"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <DatePicker
              label="Platnost od"
              v-model="additionalCostRegistry.dateFrom"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
            /> </v-col
          ><v-col cols="12" class="py-0">
            <DatePicker
              label="Platnost do"
              v-model="additionalCostRegistry.dateTo"
              jePovinne
              :povolenaEditace="formMode != formModes.read"
          /></v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  AdditionalCostsRegistryCreateModel,
  AdditionalCostsRegistryUpdateModel
} from '../../store/models/additionalCostsRegistryModels';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getAdditionalCostsRegistryById(this.idProp);
      await this.verifySuccessCall();
    }
    this.original = this.$_.cloneDeep(this.additionalCostsRegistry);
  },
  computed: {
    ...window.get(['formModes']),
    ...window.sync(['additionalCostsRegistry/additionalCostRegistry']),
    formTitle() {
      return `Vícenáklad - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'additionalCostsRegistry/getAdditionalCostsRegistryById',
      'additionalCostsRegistry/createAdditionalCostsRegistry',
      'additionalCostsRegistry/updateAdditionalCostsRegistry'
    ]),
    async close(confirmed) {
      if (
        !confirmed &&
        this.isDifferent(this.additionalCostsRegistry, this.original)
      ) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.additionalCostRegistry = {};
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createAdditionalCostsRegistry(
          new AdditionalCostsRegistryCreateModel(
            this.additionalCostRegistry.name,
            this.additionalCostRegistry.unitPrice,
            this.additionalCostRegistry.dateFrom,
            this.additionalCostRegistry.dateTo
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Vícenáklad byl vytvořen.');
          await this.close(true);
        }
      } else {
        await this.updateAdditionalCostsRegistry(
          new AdditionalCostsRegistryUpdateModel(
            this.additionalCostRegistry.name,
            this.additionalCostRegistry.unitPrice,
            this.additionalCostRegistry.dateFrom,
            this.additionalCostRegistry.dateTo,
            this.additionalCostRegistry.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Vícenáklad byl upraven.');
          await this.close(true);
        }
      }
    }
  }
};
</script>

<style></style>
