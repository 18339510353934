import Vue from 'vue';
import Vuex from 'vuex';
import vuexPathify from '../plugins/vuexPathify';
import vuexSession from '../plugins/vuexSession';
import vuexLocal from '../plugins/vuexLocal';
import { make } from 'vuex-pathify';

import auth from './auth';
import manage from './manage';
import userConfig from './userConfig';
import user from './user';
import userRole from './userRole';
import role from './role';
import company from './company';
import locality from './locality';
import contact from './contact';
import assortmentRegistry from './assortmentRegistry';
import growingActivityRegistry from './growingActivityRegistry';
import additionalCostsRegistry from './additionalCostsRegistry';
import otherCosts from './otherCosts';
import car from './car';
import deliveryNote from './deliveryNote';
import additionalCosts from './additionalCosts';
import assortment from './assortment';
import growingActivity from './growingActivity';
import deliveryNoteLocality from './deliveryNoteLocality';
import trafficTariffRegistry from './trafficTariffRegistry';
import dashboard from './dashboard';
import accounting from './accounting';
import priceListRegistry from './priceListRegistry';
import growingActivityRecord from './growingActivityRecord';
import offlineRegistry from './offlineRegistry';

Vue.use(Vuex);

const state = {
  API_baseUrl: null,
  globalErrorMessage: null,
  globalErrorStatus: null,
  globalErrorStatusText: null,
  loadingOverlayVisible: false,
  loadingOverlayLabel: '',
  formModes: { read: 'Detail', edit: 'Editace', new: 'Nový' },
  itemStates: [
    { id: 1, description: 'Platný' },
    { id: 2, description: 'Zrušený' }
  ]
};
const getters = {
  ...make.getters(state)
};
const mutations = {
  ...make.mutations(state),
  Configure(state, config) {
    state.API_baseUrl = config.API_baseUrl;
  }
};
const actions = {
  ...make.actions(state),
  Configure({ commit }, config) {
    commit('Configure', config);
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    manage,
    userConfig,
    user,
    userRole,
    role,
    company,
    locality,
    contact,
    otherCosts,
    assortmentRegistry,
    growingActivityRegistry,
    additionalCostsRegistry,
    car,
    deliveryNote,
    additionalCosts,
    assortment,
    growingActivity,
    deliveryNoteLocality,
    trafficTariffRegistry,
    dashboard,
    accounting,
    priceListRegistry,
    growingActivityRecord,
    offlineRegistry
  },
  plugins: [vuexSession.plugin, vuexLocal.plugin, vuexPathify.plugin]
});
