<template>
  <div class="pa-4">
    <h1>ForestCare</h1>
    <p>Aplikace pro evidenci komplexních služeb v lesnictví.</p>
    <h2>Kontakty</h2>
    <u>Antonín Pelant(vývojář)</u>
    <br />
    <span>Tel: 732 319 452</span>
    <br />
    <span>Email: a.pelant@dynavity.cz</span>
    <div class="pb-6"></div>
    <u>Daniel Škalda (vývojář)</u> <br />
    <span>Tel: 736 782 178</span>
    <br />
    <span>Email: d.skalda@dynavity.cz</span>
    <div class="pb-6"></div>
    <b>Podpora na emailu: </b>
    <a href="mailto:info@dynavity.cz">info@dynavity.cz</a>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
