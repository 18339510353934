<template>
  <transition name="login">
    <router-view> </router-view>
  </transition>
</template>

<script>
import { get, sync, call } from 'vuex-pathify';
import Vue from 'vue';
import EventBus from './event-bus';
export default Vue.extend({
  name: 'App',
  computed: {
    ...sync(['auth/isAuthenticated']),
    ...get(['userConfig/getColorMode'])
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      onLine: navigator.onLine
    };
  },
  created() {
    this.$vuetify.theme.dark = this.getColorMode == 'dark';
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  watch: {
    async onLine(newVal) {
      if (newVal) {
        this.$dialog.notify.info(
          'Připojení k internetu je nyní dostupné. </br> Spouštění synchronizace...'
        );
        EventBus.$emit('online', {});
        await this.updateOfflineRegistry();
      }
    }
  },
  async mounted() {
    await this.updateOfflineRegistry();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    ...call(['offlineRegistry/getData']),
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
    },
    async updateOfflineRegistry() {
      await this.getData();
      this.$dialog.notify.info(
        'Číselníky pro offline režim byly aktualizovány.'
      );
    }
  }
});
</script>
