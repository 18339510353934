class DeliveryNoteLocalityCreateModel {
  localityId: number;
  constructor(localityId: number) {
    this.localityId = localityId;
  }
}

class DeliveryNoteLocalityUpdateModel {
  localityId: number;
  id: number;
  constructor(localityId: number, id: number) {
    this.localityId = localityId;
    this.id = id;
  }
}

export { DeliveryNoteLocalityCreateModel, DeliveryNoteLocalityUpdateModel };
