/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  userRoles: []
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getUserRoleById({ commit }, userId) {
    try {
      const { data } = await axios.get(`userRoles/get/${userId}`);
      commit('userRoles', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async addUserRole({}, addModel) {
    try {
      await axios.post('userRoles/add', addModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteUserRole({}, { userId, roleId }) {
    try {
      await axios.delete(`userRoles/delete/${userId}/${roleId}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
