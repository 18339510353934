<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="!povolenaEditace"
  >
    <template #activator="{ on, attrs }">
      <v-tooltip v-model="showTooltip" top nudge-bottom="15">
        <template #activator="{}">
          <v-text-field
            ref="datePickerTextField"
            :value="computedDateFormatted"
            :readonly="range"
            hide-details="true"
            v-bind="{ ...attrs, ...computedProps }"
            v-on="Object.assign($listeners, range ? on : {})"
            @click:prepend="menu = true"
            :style="textFieldStyle"
            :class="computedClass"
            @blur="!range && setValue(computedDateFormatted, true)"
            :name="Math.random()"
            @input="onTextInput"
            @keydown="onKeyDownEventCheck"
            @keyup.up="onKeyUp"
            @keyup.down="onKeyDown"
            @keydown.up.prevent
            @keydown.down.prevent
            @keyup.left="onKeyLeft"
            @keyup.right="onKeyRight"
            @keydown.left.prevent
            @keydown.right.prevent
            @click="onClick"
            :clearable="false"
          >
            <template #append>
              <v-icon
                @click="clearValue"
                tabindex="-1"
                v-if="povolenaEditace && computedDateFormatted"
              >
                $clear
              </v-icon>
            </template>
          </v-text-field>
        </template>
        <span>{{ errorMessage }}</span>
      </v-tooltip>
    </template>
    <v-date-picker
      :value="computedPickerValue"
      @input="onInput"
      scrollable
      locale="cs-CZ"
      :first-day-of-week="1"
      :range="range"
    >
      <div style="width: 100%">
        <v-btn-toggle
          v-model="option"
          @change="onOptionChange"
          dense
          mandatory
          class="d-flex justify-center align-center"
          v-if="range"
        >
          <v-btn text value="from">Zvolit od</v-btn>
          <v-btn text value="to">Zvolit do</v-btn>
          <v-btn text value="fromTo">Od - Do</v-btn>
        </v-btn-toggle>
        <div class="d-flex justify-space-around align-center">
          <v-btn text color="primary" @click="clearValue">Smazat</v-btn>
          <v-btn text color="primary" @click="menu = false">Zavřít</v-btn>
        </div>
      </div>
      <v-spacer />
    </v-date-picker>
  </v-menu>
</template>

<script>
import { VTextField } from 'vuetify/lib';
import isInViewport from '../../mixins/isInViewportMixin';

export default {
  extends: VTextField,
  mixins: [isInViewport],
  props: {
    povolenaEditace: { type: Boolean, default: true },
    jePovinne: { type: Boolean, default: false },
    textFieldStyle: { type: String, default: null },
    textFieldClass: { type: Object, default: null },
    range: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    setFocus: { type: Boolean, default: false },
    max: { type: String }, // Maximum allowed date/month (ISO 8601 format)
    min: { type: String } // Minimum allowed date/month (ISO 8601 format)
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      menu: false,
      date: null,
      time: '00:00',
      appliedRules: null,
      requiredRule: () => !!this.computedDateFormatted || 'Pole je povinné',
      formatRule: value =>
        !value ||
        !!this.supportedFormat.find(f =>
          this.$moment(
            this.range && !this.chooseTwoValues ? value.slice(3) : value,
            f,
            true
          )?.isValid()
        ) ||
        '',
      supportedFormat: ['D.M.YYYY', 'D/M/YYYY', this.$moment.ISO_8601],
      option: 'fromTo',
      selectionStart: 0,
      selectionEnd: 0
    };
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      this.setRules(props.rules);

      props.disabled = !this.povolenaEditace;
      props.prependIcon = !props.hideIcon
        ? props.prependIcon || 'mdi-calendar'
        : undefined;
      props.rules = [];
      if (this.jePovinne) {
        props.rules.push(value => !!value || 'Pole je povinné');
      }
      return props;
    },
    computedDateFormatted() {
      if (!this.range) {
        if (
          typeof this.computedProps.value === 'string' &&
          this.computedProps.value?.match(/[./]/g)
        ) {
          const splitted = this.computedProps.value?.split(/[./]/g);
          if (!splitted) {
            return this.computedProps.value;
          }
          if (
            (splitted.length ?? 0) === 3 &&
            (splitted[0]?.length !== 2 ||
              splitted[1]?.length !== 2 ||
              splitted[2]?.length !== 4)
          ) {
            return this.computedProps.value;
          }
        }

        const actualFormat = this.supportedFormat.find(f =>
          this.$moment(this.computedProps.value, f, true).isValid()
        );

        return actualFormat
          ? this.formatDate(
              this.$moment(this.computedProps.value, actualFormat)
            )
          : this.computedProps.value;
      } else {
        return this.formatDate(this.computedProps.value);
      }
    },
    computedPickerValue() {
      const momentDate = this.$moment(this.date, 'DD.MM.YYYY');
      const val = this.range
        ? this.computedProps.value?.filter(el => el != null)
        : momentDate.isValid()
        ? momentDate.format('YYYY-MM-DD')
        : null;

      return val;
    },
    computedClass() {
      return this.textFieldClass ?? {};
    }
  },
  methods: {
    onClick(e) {
      if (this.range) return;

      if (e.target.selectionStart <= 2) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = 2;
      } else if (e.target.selectionStart <= 5) {
        e.target.selectionStart = 3;
        e.target.selectionEnd = 5;
      } else {
        e.target.selectionStart = 6;
        e.target.selectionEnd = 10;
      }
      this.selectionStart = e.target.selectionStart;
      this.selectionEnd = e.target.selectionEnd;
    },
    onKeyLeft(e) {
      if (this.range) return;

      if (e.target.selectionStart <= 3) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = 2;
      } else if (e.target.selectionStart <= 6) {
        e.target.selectionStart = 3;
        e.target.selectionEnd = 5;
      } else {
        e.target.selectionStart = 6;
        e.target.selectionEnd = 10;
      }
      this.selectionStart = e.target.selectionStart;
      this.selectionEnd = e.target.selectionEnd;
    },
    onKeyRight(e) {
      if (this.range) return;

      if (e.target.selectionStart < 2) {
        e.target.selectionStart = 3;
        e.target.selectionEnd = 5;
      } else {
        e.target.selectionStart = 6;
        e.target.selectionEnd = 10;
      }
      this.selectionStart = e.target.selectionStart;
      this.selectionEnd = e.target.selectionEnd;
    },
    onKeyUp(e) {
      this.moveDate(
        this.selectionStart === 0
          ? 'days'
          : this.selectionStart === 3
          ? 'months'
          : 'years',
        true
      );
      this.$nextTick(() => {
        e.target.selectionStart = this.selectionStart;
        e.target.selectionEnd = this.selectionEnd;
      });
    },
    onKeyDown(e) {
      this.moveDate(
        this.selectionStart === 0
          ? 'days'
          : this.selectionStart === 3
          ? 'months'
          : 'years',
        false
      );
      this.$nextTick(() => {
        e.target.selectionStart = this.selectionStart;
        e.target.selectionEnd = this.selectionEnd;
      });
    },
    moveDate(type, increment) {
      if (this.range) return;

      const newDate = this.$moment(this.value || undefined);
      let [years, months, days] = [
        newDate.year(),
        newDate.month() + 1,
        newDate.date()
      ];

      if (this.date) {
        if ((this.date?.match(/-/g) || []).length === 2) {
          [years, months, days] = this.date.split('-');
        } else if ((this.date?.match(/[.]/g) || []).length === 2) {
          [days, months, years] = this.date?.split('.');
        } else if ((this.date?.match(/[/]/g) || []).length === 2) {
          [days, months, years] = this.date?.split('/');
        }

        if (type === 'days') {
          if (increment) {
            days = parseInt(days, 10) + 1;
          } else {
            days = parseInt(days, 10) - 1;
          }
        } else if (type === 'months') {
          if (increment) {
            months = parseInt(months, 10) + 1;
          } else {
            months = parseInt(months, 10) - 1;
          }
        } else {
          if (increment) {
            years = parseInt(years, 10) + 1;
          } else {
            years = parseInt(years, 10) - 1;
          }
        }
      }
      this.date = newDate
        .date(days)
        .month(months - 1)
        .year(years)
        .format('DD.MM.YYYY');
      this.setValue(this.date, true);
    },
    setRules(rules) {
      if (!this.appliedRules) {
        this.appliedRules = rules;
      }
    },
    onTextInput(e) {
      if (!this.range) {
        const splitted = e?.split(/[./]/g);
        if (!splitted) {
          return;
        }
        if (
          (splitted.length ?? 0) === 3 &&
          splitted[0]?.length === 2 &&
          splitted[1]?.length === 2 &&
          splitted[2]?.length === 4
        ) {
          this.$nextTick(() => {
            this.setValue(e, true);
          });
        }
      }
    },
    onKeyDownEventCheck(e) {
      if (!e.ctrlKey && e.key.length === 1 && e.key.match(/[^0-9./]/)) {
        e.preventDefault();
        return false;
      }
    },
    setValue(value, blur) {
      if (this.range) {
        if (this.chooseTwoValues) {
          value = value?.map(el => this.$moment(el).format('YYYY-MM-DD'));
          value?.sort((a, b) => new Date(a) - new Date(b));
          this.$emit('input', value);
          this.$nextTick(() => this.$refs.datePickerTextField.focus());
        } else if (value !== undefined) {
          this.$emit(
            'input',
            this.option === 'from'
              ? [value[value.length - 1], null]
              : [null, value[value.length - 1]]
          );
          this.$nextTick(() => this.$refs.datePickerTextField.focus());
        }
      } else if (value !== undefined) {
        let returnValue = null;
        if (blur) {
          returnValue = value
            ? this.$moment(
                `${value} ${this.time}`,
                'D.M.YYYY HH:mm'
              ).toISOString(true)
            : null;
        } else {
          returnValue = value
            ? this.$moment(
                `${value} ${this.time}`,
                'YYYY-MM-DD HH:mm'
              ).toISOString(true)
            : null;
        }

        this.$emit('input', returnValue);
        this.$refs.datePickerTextField.validate();
      }
    },
    clearValue() {
      this.$refs.menu?.save();
      this.$emit('input', null);
    },
    formatDate(inputDate) {
      if (!inputDate) return null;
      if (this.range && !this.chooseTwoValues) {
        return `${this.option === 'from' ? 'Od' : 'Do'} ${this.$moment(
          inputDate[this.option === 'from' ? 0 : 1]
        ).format('L')}`;
      } else if (Array.isArray(inputDate)) {
        return inputDate.map(el => this.$moment(el).format('L')).join(' - ');
      } else {
        this.date = this.$moment(inputDate).format('L');
        return this.date;
      }
    },
    onInput(e) {
      if (this.chooseTwoValues) {
        if (e.length === 2) {
          this.menu = false;
        }
      } else {
        this.menu = false;
      }
      this.setValue(e, false);
    },
    onOptionChange() {
      let val;
      if (this.computedProps.value?.length) {
        if (this.option === 'from') {
          val = this.computedProps.value.sort(
            (a, b) => new Date(a) + new Date(b)
          );
        } else if (this.option === 'to') {
          val = this.computedProps.value.sort(
            (a, b) => new Date(a) - new Date(b)
          );
        } else {
          this.setValue(null, false);
        }
      }
      this.setValue(val, false);
    }
  },
  beforeMount() {
    if (this.range && this.value && (!this.value[0] || !this.value[1])) {
      if (this.value[0] !== null) {
        this.option = 'from';
      } else if (this.value[1] !== null) {
        this.option = 'to';
      }
    }
  },
  mounted() {
    this.$watch(
      () => this.value,
      newVal => {
        if (
          !this.range &&
          !this.chooseTwoValues &&
          this.$moment(newVal, this.$moment.ISO_8601, true)?.isValid()
        ) {
          this.time = this.$moment(newVal).format('HH:mm');
        }
      }
    );
    this.$watch(
      () => this.$refs?.datePickerTextField?.validations,
      val => {
        if (val[0] && this.isInViewport(this.$refs.datePickerTextField.$el)) {
          this.showTooltip = true;
          this.errorMessage = val[0];
          setTimeout(() => {
            this.showTooltip = false;
          }, 5000);
        } else {
          this.showTooltip = false;
        }
      }
    );

    if (this.setFocus) {
      setTimeout(() => {
        this.$refs.datePickerTextField.focus();
      }, 1);

      this.$watch(
        () => this.povolenaEditace,
        (newVal, oldVal) => {
          if (!oldVal && newVal) {
            this.$refs.datePickerTextField.focus();
          }
        }
      );
    }
  }
};
</script>
