/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import { filterOrderGetPage } from '../utils/TableFilterHelper';
import Vue from 'vue';

const getDefaultState = () => {
  return {
    companies: [],
    company: { locality: [], contact: [] },
    companyList: []
  };
};

const state = getDefaultState();

const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  resetCompanyState({ commit }) {
    commit('resetState');
  },
  async getCompanies({ commit, rootState }, filterOptions) {
    try {
      const { data } = await axios.get('company/get');
      const filteredData = filterOrderGetPage(data, filterOptions);
      commit('companies', filteredData);
    } catch (e) {
      commit('companies', rootState.offlineRegistry.companies);
      // error handling is in interceptor
    }
  },
  async getCompanyList({ commit }) {
    try {
      const { data } = await axios.get('company/list');
      commit('companyList', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getCompanyById({ commit }, id) {
    try {
      const { data } = await axios.get(`company/get/${id}`);
      commit('company', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createCompany({}, createCompanyModel) {
    try {
      await axios.post(`company/create`, createCompanyModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateCompany({}, updateCompanyModel) {
    try {
      await axios.put(`company/update`, updateCompanyModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteCompany({}, id) {
    try {
      await axios.delete(`company/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = {
  ...make.mutations(state),
  resetState(state) {
    Vue.set(state, 'company', getDefaultState().company);
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
