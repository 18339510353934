/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';
import moment from 'moment';
import Vue from 'vue';

const getDefaultState = () => {
  return {
    deliveryNotes: [],
    deliveryNote: {
      assortment: [],
      additionalCosts: [],
      otherCosts: [],
      localities: [],
      growingActivity: [],
      traffic: {}
    },
    trafficChargingOptions: [
      {
        key: 2,
        value: 'Účtovat odběratel'
      },
      {
        key: 1,
        value: 'Účtovat dodavatel'
      },
      {
        key: 3,
        value: 'Neúčtovat'
      }
    ],
    assortmentTypes: [
      {
        key: 2,
        value: 'Jehličnatý'
      },
      {
        key: 1,
        value: 'Listnatý'
      }
    ]
  };
};

const getPagingParams = options => {
  return {
    PageIndex: options.page,
    PageCount: options.itemsPerPage,
    SortColumn:
      options.sortBy[0] != null
        ? options.sortBy[0].charAt(0).toUpperCase() + options.sortBy[0].slice(1)
        : null,
    SortOrder:
      options.sortDesc[0] != undefined ? (options.sortDesc[0] ? 2 : 1) : null
  };
};

const state = getDefaultState();
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  resetDeliveryNoteState({ commit }) {
    commit('resetState');
  },
  async getDeliveryNotes({ commit }, options) {
    try {
      const response = await axios.get('deliveryNote/get', {
        params: {
          ...getPagingParams(options),
          fulltext: options.fulltext,
          from: options.from,
          // to: options.to,
          to: moment(options.to)
            .utc(true)
            .endOf('day')
            .toISOString(),
          userId: options.userId
        }
      });
      commit('deliveryNotes', response.data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getDeliveryNoteById({ commit }, id) {
    try {
      const { data } = await axios.get(`deliveryNote/get/${id}`);
      commit('deliveryNote', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createDeliveryNote({}, createDeliveryNoteModel) {
    try {
      await axios.post(`deliveryNote/create`, createDeliveryNoteModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateDeliveryNote({}, updateDeliveryNoteModel) {
    try {
      await axios.put(`deliveryNote/update`, updateDeliveryNoteModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteDeliveryNote({}, id) {
    try {
      await axios.delete(`deliveryNote/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = {
  ...make.mutations(state),
  resetState(state) {
    Vue.set(state, 'deliveryNote', getDefaultState().deliveryNote);
  }
};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
