<template>
  <v-container>
    <v-card class="v-card-movable">
      <v-toolbar dense class="v-toolbar-scrollable">
        <Button
          tooltip="Založení nového záznamu"
          @click="showDialog(formModes.new)"
          ><v-icon>mdi-plus</v-icon></Button
        >
        <Button
          tooltip="Zobrazení detailu záznamu"
          @click="showDialog(formModes.read)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-file-outline</v-icon></Button
        >
        <Button
          tooltip="Změna údajů záznamu"
          @click="showDialog(formModes.edit)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-pencil</v-icon></Button
        >
        <Button
          tooltip="Zrušení (zneaktivnění) záznamu"
          @click="deleteRow"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-delete-outline</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button tooltip="Zobrazí nápovědu" :povolenaEditace="false"
          ><v-icon>mdi-help-circle-outline</v-icon></Button
        >
        <Button
          tooltip="Zobrazí tiskovou sestavu"
          :uri="`/deliveryNote/get/pdf?id=${selectedRow && selectedRow.id}`"
          :fileName="`Dodací list ${selectedRow && selectedRow.sequenceNumber}`"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-printer</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button
          tooltip="Export aktivních odběratelů"
          @click="showExportSuppliersWindow()"
          ><v-icon>mdi-file-export-outline</v-icon></Button
        >
        <Button tooltip="Export přehledu aut" @click="showExportCarsWindow()"
          ><v-icon>mdi-truck-delivery-outline</v-icon></Button
        >
        <Button
          tooltip="Export přehledu dodacích listů"
          @click="showExportDeliveryNotesWindow()"
          ><v-icon>mdi-application-export</v-icon></Button
        >
        <v-divider vertical class="mx-2"></v-divider>
        <Button
          tooltip="Kopírovat dodací list"
          @click="showDialog(formModes.edit, true)"
          :povolenaEditace="!!selectedRow"
          ><v-icon>mdi-content-duplicate</v-icon></Button
        >
      </v-toolbar>
      <v-container ref="filtry" class="pt-1 pb-3">
        <v-row dense class="d-flex align-center">
          <v-col md="2" cols="12">
            <InputText
              label="Fulltextové vyhledávání"
              v-model="filters.fulltext"
              clearable
            />
          </v-col>
          <v-col md="2" cols="12">
            <DatePicker label="Datum od" v-model="filters.from" />
          </v-col>
          <v-col md="2" cols="12">
            <DatePicker label="Datum do" v-model="filters.to" />
          </v-col>
          <v-col md="2" cols="12">
            <Select
              :items="users"
              label="Uživatel"
              item-text="lastname"
              item-value="id"
              pridatVse
              v-model="filters.userId"
              clearable
            />
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="d-flex justify-end align-self-end ml-auto"
          >
            <Button @click="loadData" color="primary">Načíst</Button>
          </v-col>
        </v-row>
      </v-container>
      <DataTable
        :headers="headers"
        :items="deliveryNotesList"
        item-key="id"
        :selectedItem.sync="selectedRow"
        @dblclick:row="showDialog(formModes.read)"
        :options.sync="options"
        :server-items-length="totalRecords"
        single-select
        @contextmenu:row="showContextMenu"
        single-expand
        show-expand
        :blockPaging="false"
      >
        <template v-slot:[`item.destination`]="{ item }">
          {{
            `${item.destination.name}${(item.destination.city &&
              item.destination.city.length !== 0 &&
              ' - ' + item.destination.city.trim()) ||
              ''}`
          }}
        </template>
        <template v-slot:[`item.localities`]="{ item }">
          {{
            `${item.companyFrom &&
              item.companyFrom.name} - ${item.deliveryNotesLocalities &&
              item.deliveryNotesLocalities
                .map(el => el.locality.name)
                .join(', ')}`
          }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date ? $moment(item.date).format('L') : '' }}
        </template>
        <template v-slot:[`item.assortment`]="{ item }">
          {{
            item.assortment
              ? round2Decimals($_.sumBy(item.assortment, 'amount'))
                  .toString()
                  .replace('.', ',')
              : 0
          }}
        </template>
        <template v-slot:[`item.averageBuyingPrice`]="{ item }">
          {{
            Math.round(
              item.assortment.length
                ? item.totalBuyingPrice / $_.sumBy(item.assortment, 'amount')
                : 0
            )
          }}
        </template>
        <template v-slot:[`item.averageSellingPrice`]="{ item }">
          {{
            Math.round(
              item.assortment.length
                ? item.totalSellingPrice / $_.sumBy(item.assortment, 'amount')
                : 0
            )
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <DataTable
              :headers="headersExpanded"
              :items="item.assortment"
              item-key="id"
              hide-default-footer
              single-select
              class="pt-1 pb-2"
            >
              <template v-slot:[`item.identificator`]="{ item }">
                {{ item.assortmentRegistry.identificator }}
              </template>
              <template v-slot:[`item.name`]="{ item }">
                {{ item.assortmentRegistry.name }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount }}
              </template>
              <template v-slot:[`item.totalBuyingPrice`]="{ item }">
                {{ item.amount * item.assortmentRegistry.unitBuyingPrice }}
              </template>
              <template v-slot:[`item.totalSellingPrice`]="{ item }">
                {{ item.amount * item.assortmentRegistry.unitSellingPrice }}
              </template>
            </DataTable>
          </td>
        </template>
      </DataTable>
      <v-menu
        v-model="contextMenuVisible"
        :disabled="!selectedRow"
        :position-x="rightClickPositionX"
        :position-y="rightClickPositiony"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="showDialog(formModes.read)">
            <v-list-item-icon>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Detail</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="showDialog(formModes.edit)">
            <v-list-item-icon> <v-icon>mdi-pencil</v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Editace</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="deleteRow">
            <v-list-item-icon>
              <v-icon>mdi-delete-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zrušit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card-actions
        ><SelectFromCisButtons :zvolenyZaznam="selectedRow"
      /></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import SuppliersExport from '@/components/SuppliersExport.vue';
import CarsOverviewExport from '@/components/CarsOverviewExport.vue';
import DeliveryNoteDetail from '../components/DeliveryNoteDetail';
import dataTableContextMenuMixin from '../mixins/datatableContextMenuMixin';
import selectFromCisButtons from '../mixins/selectFromCisButtons';
import DeliveryNotesOverviewExport from '@/components/DeliveryNotesOverviewExport';

export default {
  mixins: [dataTableContextMenuMixin, selectFromCisButtons],
  data() {
    return {
      rows: [],
      headers: [
        { text: 'Číslo', value: 'sequenceNumber', width: '10%' },
        { text: 'Číslo záměru', value: 'intentionNumber', width: '10%' },
        { text: 'Datum', value: 'date', width: '10%' },
        { text: 'Sortiment m³', value: 'assortment', width: '10%' },
        { text: 'Odkud', value: 'localities', width: '20%' },
        { text: 'Komu', value: 'destination', width: '20%' },
        {
          text: 'Výkupní cena Ø/MJ',
          value: 'averageBuyingPrice',
          width: '12%'
        },
        {
          text: 'Prodejní cena Ø/MJ',
          value: 'averageSellingPrice',
          width: '12%'
        }
      ],
      options: {},
      selectedRow: null,
      filters: {},
      totalRecords: 0,
      headersExpanded: [
        { text: 'Identifikátor', value: 'identificator', width: '20%' },
        { text: 'Název', value: 'name' },
        { text: 'Množství', value: 'amount', width: '20%' },
        {
          text: 'Celková výkupní cena MJ',
          value: 'totalBuyingPrice',
          width: '20%'
        },
        {
          text: 'Celková prodejní cena MJ',
          value: 'totalSellingPrice',
          width: '20%'
        }
      ]
    };
  },
  props: {
    isCisModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...window.get([
      'deliveryNote/deliveryNotes',
      'user/users',
      'formModes',
      'company/companyList',
      'user/userIsInRole',
      'role/appRoles'
    ]),
    deliveryNotesList() {
      return this.deliveryNotes?.items?.map(dn => ({
        ...dn,
        companyFrom: this.companyList.find(
          com => com.id === dn.deliveryNotesLocalities[0]?.locality?.companyId
        )
      }));
    }
  },
  async mounted() {
    if (this.userIsInRole(this.appRoles.administrator)) {
      await this.getUsers();
      await this.verifySuccessCall();
    }

    await this.loadData();
    this.$refs.filtry.addEventListener('keypress', evt => {
      if (evt.keyCode === 13) {
        this.loadData(); // enter
      }
    });
    this.$watch(
      () => this.options,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    ...window.call([
      'company/getCompanyList',
      'deliveryNote/getDeliveryNotes',
      'deliveryNote/deleteDeliveryNote',
      'user/getUsers'
    ]),
    async loadData() {
      this.showLoader('Načítání dodacích listů...');
      await this.getDeliveryNotes({ ...this.filters, ...this.options });
      await this.verifySuccessCall();
      await this.getCompanyList();
      await this.verifySuccessCall();

      this.totalRecords = this.deliveryNotes?.totalRows;
      this.selectedRow = null;
      this.hideLoader();
    },
    async showDialog(formMode, duplicate = false) {
      const dialogResult = await this.$dialog.showAndWait(DeliveryNoteDetail, {
        width: '60rem',
        persistent: true,
        formModeProp: formMode,
        idProp: formMode === this.formModes.new ? null : this.selectedRow.id,
        duplicate
      });
      if (dialogResult.confirmed) {
        this.loadData();
      }
    },
    async showExportSuppliersWindow() {
      await this.$dialog.showAndWait(SuppliersExport, {
        width: '50rem',
        persistent: true
      });
    },
    async showExportCarsWindow() {
      await this.$dialog.showAndWait(CarsOverviewExport, {
        width: '50rem',
        persistent: true
      });
    },
    async showExportDeliveryNotesWindow() {
      await this.$dialog.showAndWait(DeliveryNotesOverviewExport, {
        width: '50rem',
        persistent: true
      });
    },
    async deleteRow() {
      if (!(await this.confirmDelete())) {
        return;
      }
      this.showLoader('Probíhá zneaktivnění záznamu...');

      await this.deleteDeliveryNote(this.selectedRow.id);

      this.hideLoader();
      if (await this.verifySuccessCall()) {
        this.$dialog.notify.info('Zneaktivnění bylo úspěšné.');
      }
      await this.loadData();
    }
  }
};
</script>
