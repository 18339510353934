import Vue from 'vue';

// globalni mixin, je zde kvuli prechodovym animacim mezi stranky, ktere aby fungovali, musi mit okolo sebe tag<keep-alive>
// kvuli nemu se vsak komponenta jen zneaktivni, ale nevyprazdni pamet. timto se nastavi destroy po deaktivaci
export default Vue.mixin({
  methods: {
    isDifferent(obj1, obj2) {
      if (this.formMode == this.formModes.read) {
        return false;
      }
      return !this.$_.isEqual(
        this.$_.omitBy(obj1, this.$_.isNil),
        this.$_.omitBy(obj2, this.$_.isNil)
      );
    }
  }
});
