/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */

import { make } from 'vuex-pathify';
import axios from '../plugins/axios';

const state = {
  otherCosts: [],
  otherCost: {}
};
const getters = { ...make.getters(state) };
const actions = {
  ...make.actions(state),
  async getOtherCosts({ commit }, options) {
    try {
      const { data } = await axios.get('otherCosts/get');
      commit('otherCosts', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async getOtherCostById({ commit }, id) {
    try {
      const { data } = await axios.get(`otherCosts/get/${id}`);
      commit('otherCost', data);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async createOtherCost({}, createOtherCostModel) {
    try {
      await axios.post(`otherCosts/create`, createOtherCostModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async updateOtherCost({}, updateOtherCostModel) {
    try {
      await axios.put(`otherCosts/update`, updateOtherCostModel);
    } catch (e) {
      // error handling is in interceptor
    }
  },
  async deleteOtherCost({}, id) {
    try {
      await axios.delete(`otherCosts/delete/${id}`);
    } catch (e) {
      // error handling is in interceptor
    }
  }
};
const mutations = { ...make.mutations(state) };

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default module;
