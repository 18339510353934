<template
  ><v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <Select
              label="Vícenáklad"
              v-model="additionalCosts.additionalCostsRegistryId"
              :items="additionalCostsRegistry"
              item-text="name"
              item-value="id"
              jePovinne
              hledani
              :zvolitPrvni="false"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><InputNumber
              label="Množštví"
              v-model="additionalCosts.amount"
              :povolenaEditace="formMode != formModes.read"
          /></v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      ></v-card
    ></v-form
  >
</template>

<script>
export default {
  data() {
    return {
      formValid: true,
      formMode: null,
      original: null,
      additionalCosts: {}
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    additionalCostsProp: {
      type: Object,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    this.original = this.$_.cloneDeep(this.additionalCostsProp);
    this.additionalCosts = this.additionalCostsProp;

    await this.getAdditionalCostsRegistry();
    await this.verifySuccessCall();
  },
  computed: {
    ...window.get([
      'formModes',
      'additionalCostsRegistry/additionalCostsRegistry'
    ]),
    formTitle() {
      return `Vícenáklady - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call(['additionalCostsRegistry/getAdditionalCostsRegistry']),
    async close(confirmed) {
      if (!confirmed && this.isDifferent(this.additionalCosts, this.original)) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      this.$emit('submit', {
        confirmed,
        newAdditionalCosts: {
          ...this.additionalCosts,
          additionalCostsRegistry: this.additionalCostsRegistry.find(
            el => el.id == this.additionalCosts.additionalCostsRegistryId
          )
        }
      });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      await this.close(true);
    }
  }
};
</script>

<style></style>
