import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import cs from 'vuetify/lib/locale/cs';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#084f02',
        secondary: '#AEDE94',
        accent: '#2cb620',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#2cb620',
        secondary: '#AEDE94',
        accent: '#a4f629',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    locales: { cs },
    current: 'cs'
  }
});
