import Vue from 'vue';
import { sync } from 'vuex-pathify';

// globalni mixin, je zde kvuli prechodovym animacim mezi stranky, ktere aby fungovali, musi mit okolo sebe tag<keep-alive>
// kvuli nemu se vsak komponenta jen zneaktivni, ale nevyprazdni pamet. timto se nastavi destroy po deaktivaci
export default Vue.mixin({
  computed: {
    ...sync([
      'globalErrorMessage',
      'globalErrorStatus',
      'globalErrorStatusText'
    ])
  },
  methods: {
    cleanGlobalError() {
      this.globalErrorMessage = null;
      this.globalErrorStatus = null;
      this.globalErrorStatusText = null;
    },
    async verifySuccessCall() {
      if (this.globalErrorStatus) {
        await this.showErrorDialog(
          this.globalErrorMessage,
          `${this.globalErrorStatus} // Chyba`
        );
        this.cleanGlobalError();
        if (this.globalErrorStatus == 401) {
          this.$router.push({
            path: '/Account/Login'
          });
        }
        return false;
      }
      return true;
    },
    async confirmDelete() {
      return await this.$dialog.confirm({
        text: 'Opravdu si přejete smazat záznam ?',
        title: 'Varování'
      });
    },
    async confirmClose() {
      return await this.$dialog.confirm({
        text: 'Na formuláři byly provedeny změny, opravdu si přejete odejít ?',
        title: 'Varování'
      });
    },
    async showErrorDialog(text, title = null) {
      await this.$dialog.error({
        text: text,
        title: title ?? 'Chyba',
        width: '50rem',
        actions: {
          false: 'Zavřít'
        }
      });
    },
    showFormNotValid() {
      return this.$dialog.notify.error(
        'Nejsou správně vyplněna všechna vstupní pole.'
      );
    }
  }
});
