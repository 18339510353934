class CarCreateModel {
  identificator: string;
  technicalInspectionDate: Date;
  revisionDate: Date;
  excessiveDate: Date;
  vin: string;
  userId: number;
  constructor(
    identificator: string,
    technicalInspectionDate: Date,
    revisionDate: Date,
    excessiveDate: Date,
    vin: string,
    userId: number
  ) {
    this.identificator = identificator;
    this.technicalInspectionDate = technicalInspectionDate;
    this.revisionDate = revisionDate;
    this.excessiveDate = excessiveDate;
    this.vin = vin;
    this.userId = userId;
  }
}

class CarUpdateModel {
  identificator: string;
  technicalInspectionDate: Date;
  revisionDate: Date;
  excessiveDate: Date;
  vin: string;
  userId: number;
  id: number;
  deleted: Date;
  constructor(
    identificator: string,
    technicalInspectionDate: Date,
    revisionDate: Date,
    excessiveDate: Date,
    vin: string,
    userId: number,
    id: number,
    deleted: Date
  ) {
    this.identificator = identificator;
    this.technicalInspectionDate = technicalInspectionDate;
    this.revisionDate = revisionDate;
    this.excessiveDate = excessiveDate;
    this.vin = vin;
    this.userId = userId;
    this.id = id;
    this.deleted = deleted;
  }
}

export { CarCreateModel, CarUpdateModel };
