<template
  ><v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <CisBox
              label="Pěstební činnost"
              formular="Registry/GrowingActivitiesRegistry"
              valueLabel="name"
              width="60rem"
              v-model="growingActivityRecord.growingActivityRegistry"
              :povolenaEditace="formMode != formModes.read"
              jePovinne
              :propsData="{ filterDate: filterDate }"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><InputNumber
              label="Množštví"
              :max="1000000"
              v-model="growingActivityRecord.totalAmmount"
              :povolenaEditace="formMode != formModes.read"
          /></v-col>
          <v-col cols="12" class="py-0"
            ><InputNumber
              label="Vlastní cena"
              :max="1000000"
              v-model="growingActivityRecord.customPrice"
              :povolenaEditace="formMode != formModes.read"
          /></v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      ></v-card
    ></v-form
  >
</template>

<script>
export default {
  data() {
    return {
      formValid: true,
      formMode: null,
      original: null,
      growingActivityRecord: {}
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    growingActivityRecordProp: {
      type: Object,
      default: null
    },
    filterDate: {
      type: String,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    this.growingActivityRecord = this.growingActivityRecordProp;

    if (
      !this.growingActivityRecordProp.id ||
      this.growingActivityRecordProp.id <= 0
    ) {
      this.$set(this.growingActivityRecord, 'totalAmmount', 1);
    }

    this.original = this.$_.cloneDeep(this.growingActivityRecord);

    await this.getGrowingActivitiesRegistry({ date: this.filterDate });
    await this.verifySuccessCall();
  },
  computed: {
    ...window.get([
      'formModes',
      'growingActivityRegistry/growingActivitiesRegistry'
    ]),
    formTitle() {
      return `Záznam pěstební činnosti - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call(['growingActivityRegistry/getGrowingActivitiesRegistry']),
    async close(confirmed) {
      if (
        !confirmed &&
        this.isDifferent(this.growingActivityRecord, this.original)
      ) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      const growingActivityRegistry = this.growingActivitiesRegistry.find(
        el => el.id == this.growingActivityRecord.growingActivityRegistry?.id
      );

      this.$emit('submit', {
        confirmed,
        newGrowingActivityRecord: {
          ...this.growingActivityRecord,
          growingActivityRegistry: growingActivityRegistry
        }
      });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      await this.close(true);
    }
  }
};
</script>

<style></style>
