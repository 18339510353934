import VuexPersist from 'vuex-persist';

const vuexSession = new VuexPersist({
  key: 'FC_Store',
  storage: window.sessionStorage,
  reducer: state => ({
    userConfig: state.userConfig
  })
});

export default vuexSession;
