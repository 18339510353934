class OtherCostsCreateModel {
  name: string;
  price: number;
  constructor(name: string, price: number) {
    this.name = name;
    this.price = price;
  }
}

class OtherCostsUpdateModel {
  name: string;
  price: number;
  id: number;
  constructor(name: string, price: number, id: number) {
    this.name = name;
    this.price = price;
    this.id = id < 0 ? 0 : id;
  }
}

export { OtherCostsCreateModel, OtherCostsUpdateModel };
