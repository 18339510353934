<template
  ><v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <Select
              label="Vícenáklad"
              v-model="additionalCostsRegistryValue"
              :items="additionalCostsRegistry"
              item-text="name"
              item-value="id"
              jePovinne
              hledani
              :zvolitPrvni="false"
              return-object
          /></v-col>
          <v-col cols="12" class="py-0"
            ><Select
              label="Lokalita"
              v-model="localityValue"
              :items="filteredLocalities"
              item-text="name"
              item-value="id"
              jePovinne
              hledani
              :zvolitPrvni="false"
              return-object
          /></v-col> </v-row
      ></v-container>
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      ></v-card
    ></v-form
  >
</template>

<script>
export default {
  data() {
    return {
      formValid: true,
      formMode: null,
      additionalCosts: {},
      filteredLocalities: [],
      additionalCostsRegistryValue: null,
      localityValue: null
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    companyId: {
      type: Number,
      default: 0
    },
    date: {
      type: String,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;

    await this.getAdditionalCostsRegistry({ date: this.date });
    await this.verifySuccessCall();

    await this.getLocalities();
    await this.verifySuccessCall();
    this.filteredLocalities = this.localities.filter(
      el => el.companyId === this.companyId
    );
  },
  computed: {
    ...window.get(['formModes', 'locality/localities']),
    ...window.sync(['additionalCostsRegistry/additionalCostsRegistry']),
    formTitle() {
      return `Vícenáklad - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'additionalCostsRegistry/getAdditionalCostsRegistry',
      'locality/getLocalities'
    ]),
    async close(confirmed) {
      this.$emit('submit', {
        confirmed,
        newAdditionalCostsRegistryLocality: {
          additionalCostsRegistryValue: this.additionalCostsRegistryValue,
          localityValue: this.localityValue
        }
      });
      this.additionalCostsRegistry = [];
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      await this.close(true);
    }
  }
};
</script>

<style></style>
