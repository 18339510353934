<template>
  <div @mouseover="onHover" style="height:100%;">
    <v-data-table
      ref="datatable"
      v-bind="computedProps"
      v-on="$listeners"
      :class="{
        'elevation-5': true,
        smallerPaddings: smallerPaddings,
        correctSelectionCheckBoxPadding: singleSelect
      }"
      dense
      :items-per-page="rowsInTable"
      :fixed-header="true"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        itemsPerPageOptions: [10, 20, 30, 50, 100],
        showCurrentPage: true,
        disablePagination: blockPaging,
        pageText: blockPaging
          ? `${serverItemsLength} z ${serverItemsLength}`
          : '$vuetify.dataFooter.pageText'
      }"
      @click:row="selectRow"
      @contextmenu:row="selectRow"
      style="height:inherit;"
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-data-table>
    <v-tooltip
      top
      v-model="tooltip"
      :nudge-bottom="10"
      :activator="hoveredCell"
    >
      <span>{{ tooltipValue }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VDataTable } from 'vuetify/lib';
import store from '../../store/';

export default {
  data() {
    return {
      tooltip: false,
      hoveredCell: null,
      tooltipValue: null,
      rowsInTable: null
    };
  },
  extends: VDataTable,
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    selectedItem: { type: Object },
    smallerPaddings: { type: Boolean, default: false },
    blockPaging: { type: Boolean, default: true }
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.showSelect = !this.singleSelect;
      props.hideDefaultFooter = !props.hideDefaultFooter ? false : true;
      return props;
    }
  },
  beforeCreate() {
    this.constructor.options.props.singleSelect.default = () => false;
    this.constructor.options.props.disablePagination.default = () => true;
  },
  created() {
    if (isNaN(store.state.userConfig.rowsInTable)) {
      this.rowsInTable = parseInt(this.store.state.userConfig.rowsInTable, 10);
    } else {
      this.rowsInTable = store.state.userConfig.rowsInTable;
    }
  },
  mounted() {
    this.addMouseListeners();
  },
  methods: {
    selectRow(event, data) {
      if (this.value?.length === 0) {
        this.$emit('update:selectedItem', data?.item ?? null);
      } else {
        this.$emit('update:selectedItem', null);
      }
      if (this.singleSelect && !!data) {
        data?.select(true);
      }
    },
    onHover(event) {
      // zobrazeni tooltipu nad bunkou, kde se do ni nevesel cely obsah
      if (event.target.nodeName === 'TD') {
        const el = event.target;

        const isOverflowing = el.clientWidth < el.scrollWidth;
        this.hoveredCell = null;
        this.tooltip = false;

        // nezobrazovat nad checkboxem pro multiselect
        if (
          isOverflowing &&
          (el.children.length > 0
            ? el.children[0].classList.contains('v-data-table__checkbox')
            : true)
        ) {
          this.$nextTick(() => {
            this.hoveredCell = event.target;
            this.tooltip = true;
            this.tooltipValue = event.target.innerText;
          });
        }
      }
    },
    addMouseListeners() {
      const rows = this.$refs.datatable.$el.querySelectorAll('tbody tr');
      for (const row of rows) {
        row.classList.remove('selectedRow');
        row.classList.remove('v-data-table__selected');

        ['click', 'contextmenu'].forEach(userEvent =>
          row.addEventListener(userEvent, e => {
            const selectedRows = this.$refs.datatable.$el.querySelectorAll(
              '.selectedRow'
            );
            for (const row of selectedRows) {
              row.classList.remove('selectedRow');
            }

            if (this.value?.length === 0) {
              let row = e.target;
              while (row && row.nodeName !== 'TR') {
                row = row.parentNode;
              }
              row.classList.add('selectedRow');
            }
          })
        );
      }
    }
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler() {
        if (
          this.items?.length > 0 &&
          !!this.$refs.datatable?.$el?.querySelectorAll
        ) {
          this.selectRow(null, null);
          this.$nextTick(() => {
            this.addMouseListeners();
          });
        }
      }
    },
    value() {
      if (this.$refs.datatable?.$el?.querySelectorAll) {
        const selectedRows = this.$refs.datatable.$el.querySelectorAll(
          '.selectedRow'
        );
        for (const row of selectedRows) {
          row.classList.remove('selectedRow');
        }
        if (this.value?.length !== 0) {
          this.selectRow(null, null);
        }
      }
    },
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('update:selectedItem', null);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../styles/colors.scss';

.theme--light tr.selectedRow,
.theme--light tr.v-data-table__selected {
  background-color: lighten($color-accent, 50%) !important;
}
.theme--light tr.selectedRow:hover,
.theme--light tr.v-data-table__selected:hover {
  background-color: lighten($color-accent, 40%) !important;
}

.theme--dark tr.selectedRow,
.theme--dark tr.v-data-table__selected {
  background-color: lighten(#353535, 10%) !important;
}
.theme--dark tr.selectedRow:hover,
.theme--dark tr.v-data-table__selected:hover {
  background-color: lighten(#353535, 15%) !important;
}

.theme--light.v-data-table tbody tr {
  &:nth-of-type(even) {
    background-color: $alternateRowColor;
  }
  &:nth-of-type(odd) {
    background-color: $rowColor;
  }
}

.theme--dark.v-data-table tbody tr {
  &:nth-of-type(odd) {
    background-color: #252525;
  }
}

.theme--light .v-data-table {
  &
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #eaeaea;
  }
}

.theme--dark .v-data-table {
  &
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #353535;
  }
}

// zobrazeni zaznamu na jednom radku
.smallerPaddings.v-data-table .v-data-table__wrapper > table {
  & > tbody > tr > td {
    &:not(:first-child) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 0.3rem !important;
    }
    &:first-child {
      padding-right: 1.2rem !important;
      padding-left: 0.3rem !important;
    }
  }

  & > thead > tr > th {
    &:not(:first-child) {
      padding: 0px 0.3rem !important;
    }
    &:first-child {
      padding-right: 1.2rem !important;
      padding-left: 0.3rem !important;
    }
  }
}

// opraveni paddingu pri single select modu (nelze resit jinak, protoze rozmery by checkbox pri zvolenem multiselectu nebyl videt cely)
.correctSelectionCheckBoxPadding.v-data-table .v-data-table__wrapper > table {
  & > tbody > tr > td {
    &:first-child {
      padding: 0px 1rem !important;
    }
  }

  & > thead > tr > th {
    &:first-child {
      padding: 0px 1rem !important;
    }
  }
}

// zablokovani roztahnuti datatable mimo obrazovku (horizontal scrollbar)
.v-data-table .v-data-table__wrapper > table {
  table-layout: fixed;
  & > tbody > tr > td {
    &:not(:first-child) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// zablokovani oznacovani textu v cellu
.v-data-table .v-data-table__wrapper > table > tbody > tr > td {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-data-table-header.v-data-table-header-mobile tr th {
  width: 100% !important;
}

.v-data-footer__select {
  display: none;
}
</style>
