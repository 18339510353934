<template>
  <div>
    <v-tooltip v-model="showTooltip" top nudge-bottom="10">
      <template v-slot:activator="{}">
        <v-text-field
          v-if="!lines"
          ref="cisBox"
          v-bind="{ ...computedProps }"
          v-on="{ ...computedListeners }"
          :style="stylesObject"
          :value="computedValue"
          @input="e => (computedValue = e)"
          @click="openDialog"
          @click:clear="clear"
          readonly
          :hide-details="componentIsFilter"
        ></v-text-field>
        <v-textarea
          v-else
          ref="cisBox"
          v-bind="{ ...computedProps }"
          v-on="{ ...computedListeners }"
          :style="stylesObject"
          :value="computedValue"
          @input="e => (computedValue = e)"
          @click="openDialog"
          @click:clear="clear"
          readonly
          :hide-details="componentIsFilter"
        >
        </v-textarea>
      </template>
      <span>{{ errorMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components*/
import { VTextField } from 'vuetify/lib';
import isInViewport from '../../mixins/isInViewportMixin';
import Vue from 'vue';

export default {
  extends: VTextField,
  mixins: [isInViewport],
  props: {
    povolenaEditace: {
      type: Boolean,
      default: true
    },
    jePovinne: {
      type: Boolean,
      defaut: false
    },
    stylesObject: {
      type: Object,
      default: null
    },
    formular: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: '50rem'
    },
    propsData: {
      type: Object,
      default: null
    },
    valueLabel: {
      type: String || Function,
      default: null
    },
    lines: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showTooltip: false,
      errorMessage: '',
      componentIsFilter: false
    };
  },
  computed: {
    computedProps() {
      const props = { ...this.$props };
      props.disabled = !this.povolenaEditace;
      props.clearable = this.povolenaEditace;
      props.prependIcon =
        !this.componentIsFilter && !props.prependIcon
          ? 'mdi-cursor-pointer'
          : props.prependIcon;
      props.rules = [];
      if (this.jePovinne) {
        props.rules.push(value => !!value || 'Pole je povinné');
      }
      return props;
    },
    computedListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.computedValue = event;
        }
      });
    },
    computedValue: {
      get() {
        // properta valueLabel muze byt zavolana dvema zpusoby, bud s dvojteckou(metoda) nebo bez ni(properta)
        // :valueLabel="slozSpisovyZnak" - slozSpisovyZnak musi byt v methods, vstupni argument je objekt vybrany z GUI, musi obsahovat return s hodnotou pro zobrazeni
        //  valueLabel="CSpisTyp_Popis" - v objektu zvolenem v GUI vyhleda atribut CSpisTyp_Popis z objektu ve value
        if (this.value) {
          if (this.valueLabel instanceof Function) {
            return this.valueLabel(this.value);
          } else if (this.value[this.valueLabel]) {
            return this.value[this.valueLabel];
          }
        } else {
          if (this.valueLabel instanceof Function) {
            return this.valueLabel(this.value);
          }
        }
        return '';
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    async openDialog() {
      if (this.formular === null || !this.povolenaEditace) return;

      const component = Vue.component(
        this.formular.replace('/', ''),
        require('../../views/' + this.formular + '.vue').default
      );

      // aby se propsala hodnota sem, dialog komponenta musí byt uzavrena emitem s hodnotou "value" napr.
      // this.$emit('submit', { value: this.selectedRow });
      const dialogResult = await this.$dialog.showAndWait(component, {
        isCisModal: true,
        persistent: true,
        width: this.width,
        ...this.propsData
      });

      if (dialogResult?.value) {
        this.computedValue = dialogResult.value;
      }
    },
    clear() {
      this.computedValue = null;
    },
    isFilter(component) {
      if (component.$parent) {
        if (component.$parent.$refs.filtry) {
          return true;
        } else {
          return this.isFilter(component.$parent);
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.componentIsFilter = this.isFilter(this);
    // this.$watch(
    //   () => this.$refs?.cisBox?.validations,
    //   val => {
    //     if (val[0] && this.isInViewport(this.$refs.cisBox.$el)) {
    //       this.showTooltip = true;
    //       this.errorMessage = val[0];
    //       setTimeout(() => {
    //         this.showTooltip = false;
    //       }, 5000);
    //     } else {
    //       this.showTooltip = false;
    //     }
    //   }
    // );
  }
};
</script>
