<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <v-card class="v-card-movable">
      <v-card-title
        class="d-flex justify-space-between align-center px-4 py-2 mb-3"
        >{{ formTitle }}
        <Button large rounded icon @click="close(false)" color="error"
          ><v-icon>mdi-close</v-icon></Button
        ></v-card-title
      >
      <v-container class="pa-4 pt-0"
        ><v-row dense
          ><v-col cols="12" class="py-0">
            <v-tabs v-model="tab" centered icons-and-text show-arrows>
              <v-tab href="#tab-details">
                Detail
                <v-icon>mdi-card-account-details</v-icon>
              </v-tab>
              <v-tab href="#tab-localities">
                Lokality
                <v-icon>mdi-map-marker</v-icon>
              </v-tab>
              <v-tab href="#tab-contacts">
                Kontakty
                <v-icon>mdi-phone</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="height:30rem;">
              <v-tab-item
                value="tab-details"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" class="py-0"
                        ><InputText
                          label="Název"
                          v-model="company.name"
                          jePovinne
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0"
                        ><InputText
                          label="Město"
                          v-model="company.city"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0">
                        <InputText
                          label="Ulice"
                          v-model="company.street"
                          :povolenaEditace="formMode != formModes.read"
                        />
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <InputMask
                          label="PSČ"
                          v-model="company.zipCode"
                          mask="psc"
                          :povolenaEditace="formMode != formModes.read"
                        />
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <InputMask
                          label="IČO"
                          v-model="company.in"
                          mask="ico"
                          :povolenaEditace="formMode != formModes.read"/></v-col
                      ><v-col cols="12" class="py-0">
                        <InputMask
                          label="DIČ"
                          v-model="company.tin"
                          mask="dic"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0">
                        <InputText
                          label="Bankovní účet"
                          v-model="company.bankAccount"
                          :povolenaEditace="formMode != formModes.read"
                      /></v-col>
                      <v-col cols="12" class="py-0">
                        <Textarea
                          label="Poznámka"
                          v-model="company.note"
                          :rows="3"
                          :povolenaEditace="
                            formMode != formModes.read
                          "/></v-col></v-row
                  ></v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="tab-localities"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container style="height:100%;">
                    <v-row dense style="height:100%;">
                      <v-col cols="12">
                        <v-toolbar dense class="v-toolbar-scrollable">
                          <Button
                            tooltip="Založení nového záznamu"
                            @click="showDialogLocality(formModes.new)"
                            ><v-icon>mdi-plus</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazení detailu záznamu"
                            @click="showDialogLocality(formModes.read)"
                            :povolenaEditace="!!selectedRowLocality"
                            ><v-icon>mdi-file-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Změna údajů záznamu"
                            @click="showDialogLocality(formModes.edit)"
                            :povolenaEditace="!!selectedRowLocality"
                            ><v-icon>mdi-pencil</v-icon></Button
                          >
                          <Button
                            tooltip="Zrušení (zneaktivnění) záznamu"
                            @click="deleteRowLocality"
                            :povolenaEditace="
                              !!selectedRowLocality &&
                                formMode != formModes.read
                            "
                            ><v-icon>mdi-delete-outline</v-icon></Button
                          >
                          <v-divider vertical class="mx-2"></v-divider>
                          <Button
                            tooltip="Zobrazí nápovědu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-help-circle-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazí tiskovou sestavu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-printer</v-icon></Button
                          >
                        </v-toolbar></v-col
                      >
                      <v-col cols="12" style="height: calc(100% - 56px)">
                        <DataTable
                          :headers="headersLocality"
                          :items="company.locality"
                          item-key="id"
                          :selectedItem.sync="selectedRowLocality"
                          @dblclick:row="showDialogLocality(formModes.read)"
                          :options.sync="optionsLocality"
                          :server-items-length="totalRecordsLocality"
                          single-select
                          :hide-default-footer="true"
                          @contextmenu:row="showContextMenuLocality"
                          height="100%"
                        />
                        <v-menu
                          v-model="contextMenuLocalityVisible"
                          :disabled="!selectedRowLocality"
                          :position-x="rightClickPositionX"
                          :position-y="rightClickPositiony"
                          absolute
                          offset-y
                        >
                          <v-list dense>
                            <v-list-item
                              @click="showDialogLocality(formModes.read)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-file-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Detail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="showDialogLocality(formModes.edit)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editace</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="deleteRowLocality">
                              <v-list-item-icon>
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Zrušit</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu></v-col
                      ></v-row
                    >
                  </v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="tab-contacts"
                class="pa-1"
                style="height:100%;"
                eager
              >
                <v-card style="height:100%;">
                  <v-container style="height:100%;">
                    <v-row dense style="height:100%;">
                      <v-col cols="12">
                        <v-toolbar dense class="v-toolbar-scrollable">
                          <Button
                            tooltip="Založení nového záznamu"
                            @click="showDialogContact(formModes.new)"
                            ><v-icon>mdi-plus</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazení detailu záznamu"
                            @click="showDialogContact(formModes.read)"
                            :povolenaEditace="!!selectedRowContact"
                            ><v-icon>mdi-file-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Změna údajů záznamu"
                            @click="showDialogContact(formModes.edit)"
                            :povolenaEditace="!!selectedRowContact"
                            ><v-icon>mdi-pencil</v-icon></Button
                          >
                          <Button
                            tooltip="Zrušení (zneaktivnění) záznamu"
                            @click="deleteRowContact"
                            :povolenaEditace="
                              !!selectedRowContact && formMode != formModes.read
                            "
                            ><v-icon>mdi-delete-outline</v-icon></Button
                          >
                          <v-divider vertical class="mx-2"></v-divider>
                          <Button
                            tooltip="Zobrazí nápovědu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-help-circle-outline</v-icon></Button
                          >
                          <Button
                            tooltip="Zobrazí tiskovou sestavu"
                            :povolenaEditace="false"
                            ><v-icon>mdi-printer</v-icon></Button
                          >
                        </v-toolbar></v-col
                      >
                      <v-col cols="12" style="height: calc(100% - 56px)">
                        <DataTable
                          :headers="headersContact"
                          :items="company.contact"
                          item-key="id"
                          :selectedItem.sync="selectedRowContact"
                          @dblclick:row="showDialogContact(formModes.read)"
                          :options.sync="optionsContact"
                          :server-items-length="totalRecordsContact"
                          single-select
                          :hide-default-footer="true"
                          @contextmenu:row="showContextMenuContact"
                          height="100%"
                        />
                        <v-menu
                          v-model="contextMenuContactVisible"
                          :disabled="!selectedRowContact"
                          :position-x="rightClickPositionX"
                          :position-y="rightClickPositiony"
                          absolute
                          offset-y
                        >
                          <v-list dense>
                            <v-list-item
                              @click="showDialogContact(formModes.read)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-file-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Detail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="showDialogContact(formModes.edit)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editace</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="deleteRowContact">
                              <v-list-item-icon>
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Zrušit</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu></v-col
                      ></v-row
                    >
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row></v-container
      >
      <v-card-actions class="d-flex justify-center"
        ><Button
          color="primary"
          @click="save"
          :povolenaEditace="formValid"
          v-if="formMode != formModes.read"
          >Uložit</Button
        >
        <Button
          color="primary"
          @click="formMode = formModes.edit"
          v-if="formMode == formModes.read"
          >Povolit editaci</Button
        >
        <Button @click="close(false)">Zavřít</Button></v-card-actions
      >
    </v-card>
  </v-form>
</template>

<script>
import {
  CompanyCreateModel,
  CompanyUpdateModel,
  ContactModel,
  LocalityUpdateModel
} from '../../store/models/companiesModels';
import dataTableContextMenuMixin from '../../mixins/datatableContextMenuMixin';
import LocalityDetail from './LocalityDetail';
import ContactDetail from './ContactDetail';
import { getDifferences } from '../../utils/DifferenceHelper';

export default {
  mixins: [dataTableContextMenuMixin],
  data() {
    return {
      formMode: null,
      formValid: true,
      original: null,
      tab: null,
      headersLocality: [{ text: 'Název', value: 'name' }],
      headersContact: [
        { text: 'Jméno', value: 'firstname' },
        { text: 'Příjmení', value: 'lastname' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Fax', value: 'fax' }
      ],
      optionsLocality: {},
      optionsContact: {},
      selectedRowLocality: null,
      selectedRowContact: null,
      filters: {},
      totalRecordsLocality: 0,
      totalRecordsContact: 0,
      contextMenuLocalityVisible: false,
      contextMenuContactVisible: false
    };
  },
  props: {
    formModeProp: {
      type: String,
      default: null
    },
    idProp: {
      type: Number,
      default: null
    }
  },
  async created() {
    this.formMode = this.formModeProp;
    if (this.idProp) {
      await this.getCompanyById(this.idProp);
      await this.verifySuccessCall();
    }
    this.totalRecordsLocality = this.company.locality.length;
    this.totalRecordsContact = this.company.contact.length;
    this.original = this.$_.cloneDeep(this.company);
  },
  computed: {
    ...window.get(['formModes']),
    ...window.sync(['company/company']),
    formTitle() {
      return `Zákazník - ${this.formMode}`;
    }
  },
  methods: {
    ...window.call([
      'company/getCompanyById',
      'company/createCompany',
      'company/updateCompany',
      'company/resetCompanyState',
      'locality/deleteLocality',
      'contact/deleteContact'
    ]),
    async close(confirmed) {
      if (!confirmed && this.isDifferent(this.company, this.original)) {
        if (!(await this.confirmClose())) {
          return;
        }
      }
      await this.resetCompanyState();
      this.$emit('submit', { confirmed });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showFormNotValid();
        return;
      }
      if (this.formMode == this.formModes.new) {
        await this.createCompany(
          new CompanyCreateModel(
            this.company.name,
            this.company.in,
            this.company.tin,
            this.company.city,
            this.company.street,
            this.company.zipCode,
            this.company.note,
            this.company.locality.map(
              el => new LocalityUpdateModel(el.id > 0 ? el.id : 0, el.name)
            ),
            this.company.contact.map(
              el =>
                new ContactModel(
                  el.id > 0 ? el.id : 0,
                  el.firstname,
                  el.lastname,
                  el.email,
                  el.phone,
                  el.fax
                )
            ),
            this.company.bankAccount
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Zákazník byl vytvořen.');
          await this.close(true);
        }
      } else {
        const { toDelete: localityIdToDelete } = getDifferences(
          this.original.locality,
          this.company.locality
        );

        localityIdToDelete.forEach(async el => await this.deleteLocality(el));

        const { toDelete: contactIdToDelete } = getDifferences(
          this.original.contact,
          this.company.contact
        );

        contactIdToDelete.forEach(async el => await this.deleteContact(el));

        await this.updateCompany(
          new CompanyUpdateModel(
            this.company.name,
            this.company.in,
            this.company.tin,
            this.company.city,
            this.company.street,
            this.company.zipCode,
            this.company.note,
            this.company.locality.map(
              el => new LocalityUpdateModel(el.id > 0 ? el.id : 0, el.name)
            ),
            this.company.contact.map(
              el =>
                new ContactModel(
                  el.id > 0 ? el.id : 0,
                  el.firstname,
                  el.lastname,
                  el.email,
                  el.phone,
                  el.fax
                )
            ),
            this.company.bankAccount,
            this.company.id
          )
        );
        if (await this.verifySuccessCall()) {
          this.$dialog.notify.info('Zákazník byl upraven.');
          await this.close(true);
        }
      }
    },
    async showDialogLocality(formMode) {
      const dialogResult = await this.$dialog.showAndWait(LocalityDetail, {
        width: '30rem',
        persistent: true,
        formModeProp: formMode,
        localityProp:
          formMode != this.formModes.new
            ? { ...this.selectedRowLocality }
            : {
                id:
                  this.company.locality.length != 0
                    ? this.$_.min([
                        this.$_.minBy(this.company.locality, 'id').id - 1,
                        0
                      ])
                    : 0
              }
      });
      if (dialogResult.confirmed) {
        if (this.formMode == this.formModes.read) {
          this.formMode = this.formModes.edit;
        }
        if (formMode == this.formModes.new) {
          this.company.locality.push(dialogResult.newLocality);
          this.totalRecordsLocality += 1;
          this.$dialog.notify.info('Lokalita byla přidána.');
        } else {
          const index = this.company.locality.indexOf(
            this.company.locality.find(
              el => el.id == dialogResult.newLocality.id
            )
          );
          this.company.locality.splice(index, 1);
          this.company.locality.splice(index, 0, dialogResult.newLocality);
          this.$dialog.notify.info('Lokalita byla upravena.');
        }
      }
    },
    async deleteRowLocality() {
      this.company.locality = this.company.locality.filter(
        el => el.id != this.selectedRowLocality.id
      );
      this.totalRecordsLocality -= 1;
      this.$dialog.notify.info('Lokalita byla odstraněna.');
    },
    showContextMenuLocality(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuLocalityVisible = true;
      });
    },
    async showDialogContact(formMode) {
      const dialogResult = await this.$dialog.showAndWait(ContactDetail, {
        width: '30rem',
        persistent: true,
        formModeProp: formMode,
        contactProp:
          formMode != this.formModes.new
            ? { ...this.selectedRowContact }
            : {
                id:
                  this.company.contact.length != 0
                    ? this.$_.min([
                        this.$_.minBy(this.company.contact, 'id').id - 1,
                        0
                      ])
                    : 0
              }
      });
      if (dialogResult.confirmed) {
        if (this.formMode == this.formModes.read) {
          this.formMode = this.formModes.edit;
        }
        if (formMode == this.formModes.new) {
          this.company.contact.push(dialogResult.newContact);
          this.totalRecordsContact += 1;
          this.$dialog.notify.info('Kontakt byl přidán.');
        } else {
          const index = this.company.contact.indexOf(
            this.company.contact.find(el => el.id == dialogResult.newContact.id)
          );
          this.company.contact.splice(index, 1);
          this.company.contact.splice(index, 0, dialogResult.newContact);
          this.$dialog.notify.info('Kontakt byl upraven.');
        }
      }
    },
    async deleteRowContact() {
      this.company.contact = this.company.contact.filter(
        el => el.id != this.selectedRowContact.id
      );
      this.totalRecordsContact -= 1;
      this.$dialog.notify.info('Kontakt byl odstraněn.');
    },
    showContextMenuContact(e) {
      e.preventDefault();
      this.rightClickPositionX = e.clientX;
      this.rightClickPositiony = e.clientY;
      this.$nextTick(() => {
        this.contextMenuContactVisible = true;
      });
    }
  }
};
</script>

<style></style>
