class LocalityCreateModel {
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}

class LocalityUpdateModel {
  id: number;
  name: string;
  constructor(id: number, name: string) {
    this.name = name;
    this.id = id < 0 ? 0 : id;
  }
}

export { LocalityUpdateModel, LocalityCreateModel };
