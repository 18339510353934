class TrafficTariffRegistryCreateModel {
  pricePerKM: number;
  year: number;
  to: number;
  from: number;
  assortmentType: number;
  priceListRegistryId: number;
  constructor(
    pricePerKM: number,
    year: number,
    to: number,
    from: number,
    assortmentType: number,
    priceListRegistryId: number
  ) {
    this.pricePerKM = pricePerKM;
    this.year = year;
    this.to = to;
    this.from = from;
    this.assortmentType = assortmentType;
    this.priceListRegistryId = priceListRegistryId;
  }
}

class TrafficTariffRegistryUpdateModel {
  pricePerKM: number;
  year: number;
  to: number;
  from: number;
  assortmentType: number;
  priceListRegistryId: number;
  id: number;
  constructor(
    pricePerKM: number,
    year: number,
    to: number,
    from: number,
    assortmentType: number,
    priceListRegistryId: number,
    id: number
  ) {
    this.pricePerKM = pricePerKM;
    this.year = year;
    this.to = to;
    this.from = from;
    this.assortmentType = assortmentType;
    this.priceListRegistryId = priceListRegistryId;
    this.id = id;
  }
}

export { TrafficTariffRegistryCreateModel, TrafficTariffRegistryUpdateModel };
